

import axios from "axios";
import { baseURL } from "./baseURL";

const http = axios.create({ baseURL: baseURL(), timeout: 200000 });


let isRefreshing = false;
let refreshSubscribers = [];

http.interceptors.request.use(
  async (config) => {
    if (
      config.url && (
      config.url === "/api/v1/adm/usuarios/autenticar" ||
      config.url === "/api/v2/app/cliente/redefinir-senha/solicitar" ||
      config.url === "/api/v2/app/cliente/redefinir-senha/validar" ||
      config.url === "/api/v2/config?app" ||
      config.url === "/api/v2/app/cliente/redefinir-senha" ||
      config.url.startsWith("/api/v2/app/cliente/verificacao") ||
      (config.url === "/api/v2/app/cliente" && config.method && config.method === "post"))
    ) {
      return config;
    }

    const refreshTokenFromDatabase = localStorage.getItem('token');


    if (refreshTokenFromDatabase && !isRefreshing) {
      config.headers.Authorization = `Bearer ${refreshTokenFromDatabase}`;
      refreshSubscribers = [];
    } else {
      if (!isRefreshing) {
        isRefreshing = true;
        try {       
          window.location.href = '/authentication/sign-in';
        } catch (loginError) {
          throw loginError;
        }
      }
    }
    isRefreshing = false;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);



export default http;