import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Tooltip,
} from "@mui/material";
import Loading from "components/Loading";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import formatName from "helpers/formatName";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { loadParceiroEspecifico } from "store/actions/parceiros";
import { ButtonStyle } from "..";
import ModalCadastroCategoria from "./modalCadastroCategoria";
import DataTable from "examples/Tables/DataTable";
import { formatTelefone } from "helpers";
import { AccessTime, DeleteOutlined, EditOutlined } from "@mui/icons-material";
import DefinirHorarios from "./modalDefinirHorarios";
import { LoadHorariosDeAtendimentoPorCategoria } from "store/actions/parceiros";
import { ListarCategoriasDisponiveisParaParceiro } from "store/actions/categorias";
import MDBadge from "components/MDBadge";
import { ActionDeletarCategoriaParceiro } from "store/actions/parceiros";
import ModalEditarCadastro from "./modalEditarCategoria";

function ParceiroDetalhes() {
  const params = useParams();
  const { id } = params;
  const [openModalEditar, setOpenModalEditar] = useState(false);
  const [modalEditarData, setModalEditarData] = useState({});
  const { parceiro, loading, categorias } = useSelector(
    (state) => state.parceiros
  );
  const [openModalCadastro, setOpenModalCadastro] = useState(false);
  const [openModalHorarios, setOpenModalHorarios] = useState(false);
  const [categoriaId, setCategoriaId] = useState();

  const [openDialog, setOpenDialog] = useState(false);
  const [exclusaoData, setExclusaoData] = useState({});

  useEffect(() => {
    loadParceiroEspecifico(id);
  }, []);

  async function OpenModalHorariosCadastrados(data) {
    setCategoriaId(data);
    try {
      await LoadHorariosDeAtendimentoPorCategoria(data.id);
      setOpenModalHorarios(true);
    } catch (e) {
      console.log(e);
    }
  }

  const Author = ({ categoria }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {categoria && formatName(categoria)}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Contatos = ({ celular, whatsapp }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {celular && formatTelefone(celular)}
        </MDTypography>
        <MDTypography variant="caption">
          Tem Whatsapp:{" "}
          {whatsapp ? (
            <span style={{ color: "green", fontWeight: "bold" }}>Sim</span>
          ) : (
            <span style={{ color: "red", fontWeight: "bold" }}>Não</span>
          )}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const columns = [
    { Header: "Categoria", accessor: "categoria", align: "left" },
    { Header: "Status Categoria", accessor: "status_categoria", align: "left" },

    { Header: "Contato", accessor: "contato", align: "left" },
    { Header: "Descricao", accessor: "descricao", align: "left" },
    { Header: "Ação", accessor: "action", align: "center" },
  ];

  const styleBtn = {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  };

  async function RemoverCategoriaFunction() {
    await ActionDeletarCategoriaParceiro({
      categoria_parceiro_id: exclusaoData.id,
      parceiro_id: exclusaoData.parceiro_id,
    });
    loadParceiroEspecifico(id);
    setExclusaoData({});
    setOpenDialog(false);
  }
  function OpenDialogExclusao(data) {
    setExclusaoData(data);
    setOpenDialog(true);
  }

  function OpenModalEditarCategoria(data) {
    console.log(data);
    setModalEditarData(data);
    setOpenModalEditar(true);
  }

  const rows = categorias.map((item) => {
    return {
      categoria: <Author categoria={item.categoria} />,
      status_categoria:
        item.status_categoria === "A" ? (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent="Ativo"
              color="success"
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ) : (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent="CATEGORIA INATIVA - NÃO EXIBIDA NO APP"
              color="dark"
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
      contato: (
        <Contatos celular={item.celular} whatsapp={item.celular_whatsapp} />
      ),
      descricao: (
        <div style={{ width: 280, height: 110, overflowY: "scroll" }}>
          {item.descricao}
        </div>
      ),
      action: (
        <>
          {item.status_categoria === "A" ? (
            <>
              <Tooltip title="Editar informações">
                <button
                  style={{
                    ...styleBtn,
                    fontSize: 25,
                  }}
                  onClick={() => [
                    setModalEditarData(item),
                    setOpenModalEditar(true),
                  ]}
                >
                  <EditOutlined style={{ color: "#18bc36" }} />
                </button>
              </Tooltip>
              <Tooltip title="Horários de Atendimento">
                <button
                  style={{
                    ...styleBtn,
                    fontSize: 25,
                    marginLeft: 15,
                  }}
                  onClick={() => OpenModalHorariosCadastrados(item)}
                >
                  <AccessTime style={{ color: "#3992EE" }} />
                </button>
              </Tooltip>
              <Tooltip title="Excluir categoria">
                <button
                  style={{
                    ...styleBtn,
                    fontSize: 25,
                    marginLeft: 15,
                  }}
                  onClick={() => OpenDialogExclusao(item)}
                >
                  <DeleteOutlined style={{ color: "#ff0000" }} />
                </button>
              </Tooltip>
            </>
          ) : (
            <Tooltip title="Excluir categoria">
              <button
                style={{
                  ...styleBtn,
                  fontSize: 25,
                  marginLeft: 15,
                }}
                onClick={() => OpenDialogExclusao(item)}
              >
                <DeleteOutlined style={{ color: "#ff0000" }} />
              </button>
            </Tooltip>
          )}
        </>
      ),
    };
  });

  return (
    <DashboardLayout>
      <Loading loading={loading} />
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <img src="http://localhost:3001/api/v1/adm/parceiros/logo/d113dd12-463c-4bb8-a9b7-f9800c46d106.png" /> */}
                  <MDTypography variant="h6" color="white">
                    Razão Social: {parceiro && formatName(parceiro.nome)} <br />
                    <MDTypography variant="caption" color="white">
                      Nome Fantasia:{" "}
                      {parceiro.nome_fantasia &&
                        formatName(parceiro.nome_fantasia)}
                    </MDTypography>
                  </MDTypography>

                  <ButtonStyle onClick={() => setOpenModalCadastro(true)}>
                    CADASTRAR CATEGORIA
                  </ButtonStyle>
                </div>
              </MDBox>
              <Paper elevation={1} style={{ padding: 5 }}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </Paper>
            </Card>
          </Grid>
        </Grid>

        <ModalCadastroCategoria
          open={openModalCadastro}
          close={() => setOpenModalCadastro(false)}
          data={parceiro}
          loadParceiro={() => loadParceiroEspecifico(id)}
        />
        <DefinirHorarios
          open={openModalHorarios}
          close={() => setOpenModalHorarios(false)}
          data={categoriaId}
        />

        <ModalEditarCadastro
          open={openModalEditar}
          data={modalEditarData}
          close={() => [setOpenModalEditar(false), setModalEditarData({})]}
          updateData={() => loadParceiroEspecifico(id)}
        />
      </MDBox>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Deseja realmente excluir a categoria?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta ação resultará na exclusão da categoria{" "}
            <b>{exclusaoData.categoria}</b> da listagem do aplicativo,
            juntamente com os horários, descrição e telefone associados a ela.
            Uma vez excluída, a categoria não poderá ser recuperada, e para
            exibi-la novamente será necessário realizar um novo cadastro.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
          <Button onClick={() => RemoverCategoriaFunction()} autoFocus>
            Sim, excluir!
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default ParceiroDetalhes;
