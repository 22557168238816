import * as types from "../types/clientes";

const INITIAL_STATE = {
  loading: false,
  clientes: [],
  page: 1,
  per_page: 10,
  total: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CLIENTES_LOADING:
      return { ...state, loading: action.payload };

    case types.CLIENTES_DATA:
      return { ...state, clientes: action.payload };

    case types.CLIENTES_DATA_PAGE:
      return { ...state, page: action.payload };

    case types.CLIENTES_DATA_PER_PAGE:
      return { ...state, per_page: action.payload };

    case types.CLIENTES_DATA_TOTAL:
      return { ...state, total: action.payload };

    default:
      return state;
  }
};
