// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import formatName from "helpers/formatName";
import dayjs from "dayjs";
import styled from "@emotion/styled";
import { loadParceiros } from "store/actions/parceiros";
import MaskCPFCNPJ from "helpers/maskCpfCnpj";
import { useNavigate } from "react-router-dom";
import Loading from "components/Loading";
import { Paper, Tooltip } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import ParceiroCadastro from "./parceiroCadastro";
import {
  EditOutlined,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
  PersonAddAlt,
  PersonOffOutlined,
  ReadMore,
} from "@mui/icons-material";
import * as types from "../../store/types/parceiros";
import ParceiroEdicaoModal from "./editarParceiro";

export const ButtonStyle = styled.button`
  background: #002244;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  border: 1px solid gray;
  cursor: pointer;
  font-size: 12px;
  &:hover {
    background: #2c3e50;
    transition: 0.3s ease-in-out;
  }
  &:active {
    background: #2c3e50;
    top: 2px;
  }
  &:before {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 140%;
    width: 60px;
  }
`;

function ParceirosApp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { parceiros, loading, page, per_page, total } = useSelector(
    (state) => state.parceiros
  );

  const [filtro, setFiltro] = useState("");
  const [filtroAtualizado, setFiltroAtualizado] = useState("");
  const [openModalEditar, setOpenModalEditar] = useState(false);
  const [modalEditarData, setModalEditarData] = useState({});
  const [resetPage, setResetPage] = useState(false);

  async function GetDataLoad() {
    const data = {
      page: resetPage ? 1 : page,
      per_page: per_page,
      filtro: filtroAtualizado,
    };

    await loadParceiros(data);
    setFiltroAtualizado("");
    setResetPage(false);
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (filtro === "") {
        GetDataLoad(); // Se filtro está vazio, chama a função GetDataLoad diretamente
      } else {
        setFiltroAtualizado(filtro);
        setResetPage(true);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [filtro, page]);

  useEffect(() => {
    if (filtroAtualizado !== "") {
      GetDataLoad();
    }
  }, [filtroAtualizado, page, resetPage]);

  const navigatePages = (validations) => {
    dispatch({ type: types.PARCEIROS_DATA_PAGE, payload: validations });
  };


  const [openModalCadastro, setOpenModalCadastro] = useState(false);

  const Author = ({ nome, nome_fantasia, documento }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {nome && formatName(nome)}
        </MDTypography>
        <MDTypography variant="caption">
          Nome Fantasia: {nome_fantasia && formatName(nome_fantasia)}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Contato = ({ data }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {data.endereco && formatName(data.endereco)}{" "}
        {data.numero && formatName(data.numero)},{" "}
      </MDTypography>
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {data.cidade && formatName(data.cidade)}/{data.uf}
      </MDTypography>
    </MDBox>
  );

  const columns = [
    { Header: "Data", accessor: "data", align: "left" },
    { Header: "Cliente", accessor: "nome", align: "left" },
    { Header: "Endereço", accessor: "endereco", align: "left" },
    { Header: "Ação", accessor: "action", align: "center" },
  ];

  const styleBtn = {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  };

  const rows = parceiros.map((item) => {
    return {
      nome: (
        <Author
          nome={item.nome}
          nome_fantasia={item.nome_fantasia}
          documento={item.cpf_cnpj}
        />
      ),
      endereco: <Contato data={item} />,
      data: dayjs(item.created_at).format("DD/MM/YYYY"),
      action: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Tooltip title="Editar Parceiro">
              <button
                style={{
                  ...styleBtn,
                  fontSize: 25,
                }}
                onClick={() => [
                  setModalEditarData(item),
                  setOpenModalEditar(true),
                ]}
              >
                <EditOutlined style={{ color: "#18bc36" }} />
              </button>
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Detalhes">
              <button
                style={{
                  ...styleBtn,
                  fontSize: 25,
                  marginLeft: 15,
                }}
                onClick={() => navigate(`/parceiros/${item.cpf_cnpj}`)}
              >
                <ReadMore style={{ color: "#3690ED" }} />
              </button>
            </Tooltip>
          </div>
        </div>
      ),
    };
  });

  const styledBtnArrows = {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    border: "none",
    cursor: "pointer",
    backgroundColor: "transparent",
    fontFamily: "Poppins",
  };

  return (
    <DashboardLayout>
      <Loading loading={loading} />
      <DashboardNavbar
        filtroNome="Buscar Parceiro Pelo Nome"
        valueFiltro={filtro}
        onChangeFiltro={(e) => setFiltro(e.target.value)}
      />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <MDTypography variant="h6" color="white">
                    Parceiros App
                  </MDTypography>
                  <ButtonStyle onClick={() => setOpenModalCadastro(true)}>
                    CADASTRAR PARCEIRO
                  </ButtonStyle>
                </div>
              </MDBox>
              <Paper elevation={1} style={{ padding: 5 }}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />

                <div style={{ margin: 15 }}>
                  <div
                    style={{
                      marginRight: 30,
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {+page !== 1 && (
                        <button
                          style={styledBtnArrows}
                          onClick={() => navigatePages(+page - 1)}
                        >
                          <KeyboardArrowLeftOutlined /> Anterior{" "}
                        </button>
                      )}

                      {parceiros?.length !== 0 && (
                        <button
                          style={{ ...styledBtnArrows, marginLeft: 20 }}
                          onClick={() => navigatePages(+page + 1)}
                        >
                          Próximo <KeyboardArrowRightOutlined />
                        </button>
                      )}
                    </div>
                    <p
                      style={{
                        fontSize: 14,
                        backgroundColor: "transparent",
                        fontFamily: "Poppins",
                      }}
                    >
                      Pagina {page} | Total de clientes {total}
                    </p>
                  </div>
                </div>
              </Paper>
            </Card>
          </Grid>
        </Grid>
        <ParceiroCadastro
          open={openModalCadastro}
          close={() => setOpenModalCadastro(false)}
          updateList={GetDataLoad}
        />
        <ParceiroEdicaoModal
          open={openModalEditar}
          close={() => setOpenModalEditar(false)}
          updateList={GetDataLoad}
          data={modalEditarData}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ParceirosApp;
