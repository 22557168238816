// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import formatName from "helpers/formatName";
import dayjs from "dayjs";
import styled from "@emotion/styled";
import MaskCPFCNPJ from "helpers/maskCpfCnpj";
import { useNavigate } from "react-router-dom";
import Loading from "components/Loading";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  Tooltip,
} from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import { loadEmpresas } from "store/actions/empresas";
import EmpresasCadastro from "./empresasCadastro";
import {
  AddLink,
  DeleteOutlineOutlined,
  EditOutlined,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
  PersonOff,
  PhoneIphone,
  ReadMore,
  WhatsApp,
} from "@mui/icons-material";
import { loadClientesEmpresa } from "store/actions/empresas";
import ModalComponent from "components/Modal";
import * as types from "../../store/types/empresas";
import { ActionExcluirEmpresaEClientes } from "store/actions/empresas";
import { ActionDefinirEmpresaCadastroApp } from "store/actions/empresas";
import { CustomTooltip } from "components/CustomTooltip";

export const ButtonStyle = styled.button`
  background: #002244;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  border: 1px solid gray;
  cursor: pointer;
  font-size: 12px;
  &:hover {
    background: #2c3e50;
    transition: 0.3s ease-in-out;
  }
  &:active {
    background: #2c3e50;
    top: 2px;
  }
  &:before {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 140%;
    width: 60px;
  }
`;

const ButtonComponent = styled.button`
  background-color: transparent;
  color: #630b0b;
  width: 100%;
  padding: 10px 0px;
  border-radius: 7px 0px 7px 0px;
  border: 1px solid #630b0b;
  margin-top: 15px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: #630b0b;
    color: #fff;
    transition: 0.3s ease-in-out;
  }
`;

function EmpresasApp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { empresas, loading, page, per_page, total } = useSelector(
    (state) => state.empresas
  );
  const [openModalCadastro, setOpenModalCadastro] = useState(false);
  const [openDialogExclusao, setOpenDialogExclusao] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [filtro, setFiltro] = useState("");
  const [filtroAtualizado, setFiltroAtualizado] = useState("");

  async function GetDataLoad() {
    const data = {
      page: page,
      per_page: per_page,
      filtro: filtroAtualizado,
      status: "A",
    };
    await loadEmpresas(data);
    setFiltroAtualizado(""); // Limpa o estado após a requisição
  }

  const navigatePages = (validations) => {
    dispatch({ type: types.EMPRESAS_DATA_PAGE, payload: validations });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (filtro === "") {
        GetDataLoad(); // Se filtro está vazio, chama a função GetDataLoad diretamente
      } else {
        setFiltroAtualizado(filtro);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [filtro, page]);

  useEffect(() => {
    if (filtroAtualizado !== "") {
      GetDataLoad();
    }
  }, [filtroAtualizado, page]);

  async function LoadCliente(data) {
    try {
      await loadClientesEmpresa(data.id);
      navigate(`/empresas/${data.id}`);
    } catch (e) {
      console.log(e);
    }
  }

  function OpenDialogParaExcluirEmpresaEclientes(data) {
    setDialogData(data);
    setOpenDialogExclusao(true);
  }

  async function ExcluirEmpresaEClientes() {
    try {
      await ActionExcluirEmpresaEClientes(dialogData.id);
      await GetDataLoad()
      setOpenDialogExclusao(false);
      navigate(`/empresas`);
    } catch (e) {
      console.log(e);
    }
  }

  const Author = ({ nome, nome_fantasia, documento }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {nome && formatName(nome)}
        </MDTypography>
        <MDTypography variant="caption">
          {documento && MaskCPFCNPJ(documento)}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Contato = ({ data }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {data.endereco && formatName(data.endereco)}{" "}
        {data.numero && formatName(data.numero)},{" "}
      </MDTypography>
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {data.bairro && formatName(data.bairro)} -{" "}
        {data.cidade && formatName(data.cidade)}/{data.uf}
      </MDTypography>
    </MDBox>
  );

  const columns = [
    { Header: "Data", accessor: "data", align: "left" },
    { Header: "Cliente", accessor: "nome", align: "left" },
    { Header: "Endereço", accessor: "endereco", align: "left" },
    { Header: "Clientes Ativos", accessor: "totalClientes", align: "left" },
    { Header: "Ação", accessor: "action", align: "center" },
  ];

  const styleBtn = {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  };

  const [modalLink, setModalLink] = useState(false);
  const [dataModalLink, setDataModalLink] = useState({});
  const [linkCopy, setLinkCopy] = useState(false);

  function ModalGetLink(data) {
    const token = localStorage.getItem("white_label_token");
    setModalLink(true);
    setDataModalLink({ ...data, link: `${data.cpf_cnpj}/${token}` });
  }

 async function SetEmpresaCadastroApp(data){
    try{
      await ActionDefinirEmpresaCadastroApp({empresa_id: data.id})
      await GetDataLoad()
    } catch(e){
      console.log(e)
    }
  }

  const rows = empresas.map((item) => {
    return {
      nome: (
        <Author
          nome={item.nome}
          nome_fantasia={item.nome_fantasia}
          documento={item.cpf_cnpj}
        />
      ),
      endereco: <Contato data={item} />,
      totalClientes: item.totalClientes,
      data: dayjs(item.created_at).format("DD/MM/YYYY"),
      action: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div >
            <CustomTooltip  title={item.cadastro_app ? "EMPRESA ESCOLHIDA" :  "DEFINIR EMPRESA PARA CADASTRO NO APP" }>
              <button
                style={{
                  ...styleBtn,
                  fontSize: 25,
                  marginRight: 15,
                }}
                onClick={() => SetEmpresaCadastroApp(item)}
              >
                <PhoneIphone style={{ color: item.cadastro_app ? "#9430b2" : '#aaaaaa' }} />
              </button>
            </CustomTooltip>
            <CustomTooltip title="LINK PARA CADASTRO">
              <button
                style={{
                  ...styleBtn,
                  fontSize: 25,
                  marginRight: 15,
                }}
                onClick={() => ModalGetLink(item)}
              >
                <AddLink style={{ color: "#3892EE" }} />
              </button>
            </CustomTooltip>
            <CustomTooltip title="CLIENTES">
              <button
                style={{
                  ...styleBtn,
                  fontSize: 25,
                }}
                onClick={() => LoadCliente(item)}
              >
                <ReadMore style={{ color: "#18bc36" }} />
              </button>
            </CustomTooltip>
            <CustomTooltip title="EXCLUIR EMPRESA">
              <button
                style={{
                  ...styleBtn,
                  fontSize: 25,
                  marginLeft: 15
                }}
                onClick={() => OpenDialogParaExcluirEmpresaEclientes(item)}
              >
                <DeleteOutlineOutlined style={{ color: "red" }} />
              </button>
            </CustomTooltip>
          </div>
          {/* <div>
            <Tooltip title="Remover Clientes">
              <button
                style={{
                  ...styleBtn,
                  fontSize: 25,
                  marginLeft: 15,
                }}
                onClick={() => console.log(item, { aceite: false })}
              >
                <PersonOff style={{ color: "#ff6e00" }} />
              </button>
            </Tooltip>
          </div> */}
        </div>
      ),
    };
  });

  const handleClick = () => {
    const link = `https://api.whatsapp.com/send?text=Faça seu cadastro e aproveite os benefícios https://aliviaapp.com.br/${dataModalLink.link}`;
    window.open(link, "_blank");
  };

  async function copyLinkToPaste() {
    const link = `https://aliviaapp.com.br/${dataModalLink.link}`;

    await navigator.clipboard.writeText(link);

    setLinkCopy(true);

    setTimeout(() => {
      setLinkCopy(false);
    }, 3000); // Resetar o estado após 3 segundos
  }

  const styledBtnArrows = {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    border: "none",
    cursor: "pointer",
    backgroundColor: "transparent",
    fontFamily: "Poppins",
  };

  return (
    <DashboardLayout>
      <Loading loading={loading} />
      <DashboardNavbar
        filtroNome="Buscar Empresa Pelo Nome"
        valueFiltro={filtro}
        onChangeFiltro={(e) => setFiltro(e.target.value)}
      />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <MDTypography variant="h6" color="white">
                    Empresas App
                  </MDTypography>
                  <ButtonStyle onClick={() => setOpenModalCadastro(true)}>
                    CADASTRAR EMPRESA
                  </ButtonStyle>
                </div>
              </MDBox>
              <Paper elevation={1} style={{ padding: 5 }}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />

                <div style={{ margin: 15 }}>
                  <div
                    style={{
                      marginRight: 30,
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {+page !== 1 && (
                        <button
                          style={styledBtnArrows}
                          onClick={() => navigatePages(+page - 1)}
                        >
                          <KeyboardArrowLeftOutlined /> Anterior{" "}
                        </button>
                      )}

                      {empresas?.length !== 0 && (
                        <button
                          style={{ ...styledBtnArrows, marginLeft: 20 }}
                          onClick={() => navigatePages(+page + 1)}
                        >
                          Próximo <KeyboardArrowRightOutlined />
                        </button>
                      )}
                    </div>
                    <p
                      style={{
                        fontSize: 14,
                        backgroundColor: "transparent",
                        fontFamily: "Poppins",
                      }}
                    >
                      Pagina {page} | Total de clientes {total}
                    </p>
                  </div>
                </div>
              </Paper>
            </Card>
          </Grid>
        </Grid>
        <EmpresasCadastro
          open={openModalCadastro}
          close={() => setOpenModalCadastro(false)}
          updateList={GetDataLoad}
        />

        <ModalComponent open={modalLink} width="auto">
          <div style={{ textAlign: "center" }}>
            Compartilhar link para cadastro na {formatName(dataModalLink.nome)}
            <Divider />
            <h5 style={{ marginBottom: 15 }}>
              Clique no link abaixo para copiar ou compartilhe pelo Whatsapp
            </h5>
            {/* {dataModalLink.link} */}
            <Tooltip title="Copiar Link">
              <button
                onClick={copyLinkToPaste}
                style={{
                  background: "#3892EE",
                  width: 50,
                  height: 50,
                  borderRadius: 50,
                  border: "none",
                  cursor: "pointer",
                  marginRight: 25,
                }}
              >
                <AddLink fontSize="large" style={{ color: "#FFFFFF" }} />
              </button>
            </Tooltip>
            <Tooltip title="Enviar por whatsapp">
              <button
                onClick={handleClick}
                style={{
                  background: "#128c7e",
                  width: 50,
                  height: 50,
                  borderRadius: 50,
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <WhatsApp fontSize="large" style={{ color: "#FFFFFF" }} />
              </button>
            </Tooltip>
            {linkCopy && (
              <h4 style={{ color: "#16bf16", marginBottom: 15 }}>
                Link copiado com sucesso!
              </h4>
            )}
            <ButtonComponent onClick={() => setModalLink(false)}>
              Fechar
            </ButtonComponent>
          </div>
        </ModalComponent>

        <Dialog
          open={openDialogExclusao}
          onClose={() => setOpenDialogExclusao(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Deseja realmente excluir a empresa?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Esta ação resultará na exclusão total da empresa incluindo seus
              clientes ou funcionários vinculados a ela. Uma vez excluída, a
              empresa não poderá ser recuperada, e para exibi-la novamente será
              necessário realizar um novo cadastro, e seus clientes e usuários
              deverão cadastrar novamente no link gerado .
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
            {!loading && (
              <Button onClick={() => ExcluirEmpresaEClientes()} autoFocus>
                Sim, excluir!
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EmpresasApp;
