import { Divider, Grid } from "@mui/material";
import AlertComponent from "components/AlertComponent";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import GenericRadioButton from "components/RadioButton";
import { formatCelular } from "helpers";
import formatarDataNascimentoDB from "helpers/formatBirthDataBase";
import formatName from "helpers/formatName";
import { removeDots } from "helpers/removeDots";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ListarCategoriasDisponiveisParaParceiro } from "store/actions/categorias";
import { ActionCadatrarClienteWl } from "store/actions/clientes";
import { ActionCadastrarParceiroCategoriaWL } from "store/actions/parceiros";

const { default: ModalComponent } = require("components/Modal");

function ModalCadastroCategoria({ open, close, data, loadParceiro }) {
  if (open) {
    const [clienteData, setClienteData] = useState({
      categoria_id: "",
      descricao: "",
      whatsapp: "",
      celular: "",
      parceiro_id: data.id,
    });
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [typeMessage, setTypeMessage] = useState("");
    const [dataMessage, setDataMessage] = useState("");
    const [categoriasExistentes, setCategoriasExistentes] = useState([]);
    const [novoCadastro, setNovoCadastro] = useState(false);
    const { categorias_disponiveis } = useSelector((state) => state.categorias);

    const [selectedOption, setSelectedOption] = useState("");

    const handleSelectChange = (e) => {
      setSelectedOption(e.target.value);
      setClienteData({ ...clienteData, categoria_id: +e.target.value });
    };

    function UpdateParceiroCategoria() {
      ListarCategoriasDisponiveisParaParceiro({ parceiro_id: data.id });
    }
    useEffect(() => {
      UpdateParceiroCategoria();
    }, []);

    useEffect(() => {
      const { categoria_id, descricao, whatsapp, celular } = clienteData;
      const isFilled = categoria_id && descricao && whatsapp && celular;
      setNovoCadastro(isFilled);
    }, [clienteData]);

    function CloseModalFnc() {
      close();
      setClienteData({
        categoria_id: "",
        descricao: "",
        whatsapp: "",
        celular: "",
      });
    }

    async function EfetuarCadastroFNC() {
      try {
        await ActionCadastrarParceiroCategoriaWL({
          ...clienteData,
          whatsapp: clienteData.whatsapp === "true" ? true : false,
          celular: removeDots(clienteData.celular),
        }).then((res) => {
          UpdateParceiroCategoria();
          loadParceiro();
          setShowSuccessAlert(true);
          setTypeMessage("success");
          setDataMessage(`Cliente cadastrado com sucesso: ${clienteData.nome}`);
          setTimeout(() => {
            setShowSuccessAlert(false);
            setTypeMessage("");
            setDataMessage("");
          }, 3000);
          CloseModalFnc();
        });
      } catch (e) {
        setShowSuccessAlert(true);
        setTypeMessage("error");
        setDataMessage(e.response ? e.response.data.message : "Erro ao efetuar cadastro");
        setTimeout(() => {
          setShowSuccessAlert(false);
          setTypeMessage("");
          setDataMessage("");
        }, 3000);
      }
    }

    const styleClos = {
      backgroundColor: "transparent",
      color: "red",
      border: "none",
      cursor: "pointer",
      fontWeight: "bold",
      fontSize: 12,
    };

    const whatsappOptions = [
      { value: true, label: "Sim" },
      { value: false, label: "Não" },
      // Você pode adicionar mais opções conforme necessário
    ];

    const handleWhatsChange = (event) => {
      setClienteData({ ...clienteData, whatsapp: event.target.value });
    };

    return (
      <>
        <ModalComponent open={open} width="40%">
          {showSuccessAlert && (
            <AlertComponent type={typeMessage} message={dataMessage} timeout={3000} />
          )}

          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <MDTypography variant="h6">Cadastro de categoria</MDTypography>
            <button style={{ ...styleClos }} onClick={CloseModalFnc}>
              FECHAR X
            </button>
          </div>

          {categorias_disponiveis.length > 0 ? (
            <>
              <span style={{ fontSize: 12 }}>Selecione uma categoria para vincular ao cliente</span>
              <Divider />
              <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <MDInput
                    type="text"
                    label="Descrição"
                    multiline
                    fullWidth
                    value={clienteData.descricao}
                    onChange={(e) => setClienteData({ ...clienteData, descricao: e.target.value })}
                    inputProps={{ maxLength: 500 }}
                  />
                  <div style={{ fontSize: 12, textAlign: "right" }}>
                    {clienteData?.descricao ? clienteData?.descricao?.length : 0}/500
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div>
                    <select
                      style={{
                        padding: 15,
                        borderRadius: 7,
                        width: "100%",
                        border: "1px solid #d2d6da",
                      }}
                      value={selectedOption}
                      onChange={handleSelectChange}
                    >
                      <option value="">Selecione uma categoria</option>
                      {categorias_disponiveis.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.nome}
                        </option>
                      ))}
                    </select>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <MDInput
                    type="text"
                    label="Celular"
                    placeholder="(00)00000-0000"
                    fullWidth
                    value={
                      clienteData.celular ? formatCelular(clienteData.celular) : clienteData.celular
                    }
                    onChange={(e) => setClienteData({ ...clienteData, celular: e.target.value })}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <GenericRadioButton
                    label="Este número de celular é Whatsapp?"
                    name="whatsapp"
                    value={clienteData.whatsapp}
                    options={whatsappOptions}
                    onChange={handleWhatsChange}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={EfetuarCadastroFNC}
                    disabled={!novoCadastro}
                  >
                    Cadastrar
                  </MDButton>
                </Grid>
              </Grid>
            </>
          ) : (
            <div>
              <Divider />
              <MDTypography variant="h6">Atenção: <span style={{color: 'red'}}>Não existem categorias disponíveis para cadastro</span></MDTypography>
              <Divider />

            </div>
          )}
        </ModalComponent>
      </>
    );
  }
}

export default ModalCadastroCategoria;
