import styled from "@emotion/styled";
import { Divider } from "@mui/material";
import Loading from "components/Loading";
import ModalComponent from "components/Modal";
import { useState } from "react";
import { EnviarBanner } from "store/actions/campanhas";
import { EnviarLogoParceiro } from "store/actions/configuracoes";
import { ButtonComponent, ButtonSave } from "./styles";

function Logotipos({
  open,
  close,
  loading,
  column,
  largura,
  altura,
  idBanner,
  id,
}) {
  const [image, setImage] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  async function handleSave() {
    const queryStr = {
      tipo: column,
      column: idBanner,
      largura: largura,
      altura: altura,
      id: id,
    };
    if (image) {
      await EnviarBanner("logo", image, queryStr);
      location.reload();
      close();
    }
  }

  // Função para calcular as dimensões cortadas da imagem mantendo a proporção
  const calcularDimensoesCortadas = (larguraOriginal, alturaOriginal, tipo) => {
    let novaLargura = larguraOriginal;
    let novaAltura = alturaOriginal;

    if (tipo === "tipo1") {
      // Lógica para tipo1
      novaLargura = larguraOriginal;
      novaAltura = alturaOriginal / 2;
    } else if (tipo === "tipo2") {
      // Lógica para tipo2
      novaLargura = larguraOriginal / 2;
      novaAltura = alturaOriginal;
    } // Adicione mais lógicas para outros tipos, se necessário

    return { largura: novaLargura, altura: novaAltura };
  };

  return (
    <ModalComponent open={open} width="auto">
      <Loading loading={loading} />
      Definir banner {largura}x{altura}px
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          alignContent: "center",
        }}
      >
        <div>
          <input type="file" accept="image/*" onChange={handleFileChange} />
        </div>
        <div>
          {image && (
            <>
              <div style={{ width: 300, height: 300 }}>
                {/* Utilize as dimensões cortadas para exibir a prévia da imagem */}

                <img
                  src={URL.createObjectURL(image)}
                  alt="Preview"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              </div>
              <div style={{fontSize: 12, width: 300}}>

                <b>
                  Atenção: Se a imagem não atender aos requisitos informados,
                  ela será automaticamente cortada, o que pode afetar a exibição
                  do aplicativo.
                </b>
              </div>
              <ButtonSave onClick={handleSave}>Salvar</ButtonSave>
            </>
          )}
        </div>
        <div>
          <Divider />

          <ButtonComponent onClick={close}>Fechar</ButtonComponent>
        </div>
      </div>
    </ModalComponent>
  );
}

export default Logotipos;
