const { default: styled } = require("@emotion/styled");

export const ButtonComponent = styled.button`
background-color: transparent;
color: #630b0b;
width: 100%;
padding: 15px 0px;
border-radius: 7px 0px 7px 0px;
border: 1px solid #630b0b;
font-weight: bold;
cursor: pointer;
&:hover {
  background-color: #630b0b;
  color: #fff;
  transition: 0.3s ease-in-out;
}
`;


export const ButtonSave = styled.button`
background-color: transparent;
color: #349b63;
width: 100%;
padding: 15px 0px;
border-radius: 7px 0px 7px 0px;
border: 1px solid #349b63;
font-weight: bold;
margin-top: 15px;
cursor: pointer;
&:hover {
  background-color: #349b63;
  color: #fff;
  transition: 0.3s ease-in-out;
}
`;
