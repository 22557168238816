import customQueryStringify from "../../helpers/queryString";
import http from "../../services/api";
import store from "../index";
import * as types from "./../types/clientes";




export function loadClientes(query) {

  const qrt = customQueryStringify(query)

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.CLIENTES_LOADING, payload: true });
    http.get(`/api/v1/adm/cliente/listar?${qrt}`).then(
      (response) => {
        store.dispatch([
          { type: types.CLIENTES_DATA, payload: response.data.clientes },
          { type: types.CLIENTES_DATA_PAGE, payload: response.data.page },
          { type: types.CLIENTES_DATA_PER_PAGE, payload: response.data.per_page },
          { type: types.CLIENTES_DATA_TOTAL, payload: response.data.total },
          { type: types.CLIENTES_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.CLIENTES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}


export function ActionAceceptedOuRejected(cpf, data) {


  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.CLIENTES_LOADING, payload: true });
    http.put(`/api/v1/adm/cliente/validar/${cpf}`, data).then(
      (response) => {
        store.dispatch([
          { type: types.CLIENTES_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.CLIENTES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ActionSolicitarTokenResetCliente(cpf) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.CLIENTES_LOADING, payload: true });
    http.put(`/api/v1/adm/cliente/token/${cpf}`).then(
      (response) => {
        store.dispatch([
          { type: types.CLIENTES_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.CLIENTES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}


export function ActionCadatrarClienteWl( data) {


  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.CLIENTES_LOADING, payload: true });
    http.post(`/api/v1/adm/cliente/cadastro`, data).then(
      (response) => {
        store.dispatch([
          { type: types.CLIENTES_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.CLIENTES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

