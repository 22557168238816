function MDAlert({ color, dismissible, children, alertData, showAlert, setShowAlert }) {
  const [alertStatus, setAlertStatus] = useState("mount");

  const handleAlertStatus = () => setAlertStatus("fadeOut");

  const alertTemplate = (mount = true) => (
    <Fade in={mount} timeout={300}>
      <MDAlertRoot ownerState={{ color }}>
        <MDBox display="flex" alignItems="center" color="white">
          {alertData && alertData.message ? alertData.message : children}
        </MDBox>
        {dismissible ? (
          <MDAlertCloseIcon onClick={mount ? handleAlertStatus : null}>&times;</MDAlertCloseIcon>
        ) : null}
      </MDAlertRoot>
    </Fade>
  );

  useEffect(() => {
    if (showAlert) {
      setAlertStatus("mount");
    }
  }, [showAlert]);

  useEffect(() => {
    if (alertStatus === "mount") {
      setTimeout(() => setAlertStatus("unmount"), 5000);
    }
  }, [alertStatus]);

  switch (true) {
    case alertStatus === "mount":
      return alertTemplate();
    case alertStatus === "fadeOut":
      setTimeout(() => setAlertStatus("unmount"), 400);
      return alertTemplate(false);
    default:
      alertTemplate();
      break;
  }

  return null;
}


export default MDAlert