import { Divider, Grid } from "@mui/material";
import mockupt from "assets/images/mockup.png";
import Loading from "components/Loading";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { useSelector } from "react-redux";
import http from "services/api";
import IconeComponent from "./icone";
import Logotipos from "./logotipos";
import styled from "@emotion/styled";

function ConfiguracoesLayout() {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("");
  const [image, setImage] = useState("");
  const { loadingConfig } = useSelector((state) => state.configuracoes);
  const [modalLogo, setModalLogo] = useState(false);
  const [modalIcone, setModalIcone] = useState(false);

  // Função para obter configurações
  async function GetConfig() {
    setLoading(true);
    try {
      await http.get("/api/v1/adm/config").then((res) => {
        setColor(res.data.paleta);
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const [logotipoUrl, setLogotipoUrl] = useState(null);
  const [iconeUrl, setIconeUrl] = useState(null);
  const token = localStorage.getItem("white_label_token");

  useEffect(() => {
    if (token) {
      fetch(`https://api.aliviaapp.com.br/api/v1/adm/arquivo/logo/${token}`)
        .then((response) => {
          if (response.ok) {
            return response.blob(); // Trata a resposta como um blob (imagem)
          }
          throw new Error("Network response was not ok.");
        })
        .then((blob) => {
          // Cria uma URL temporária para o blob
          const imgUrl = URL.createObjectURL(blob);
          setLogotipoUrl(imgUrl); // Atualiza o estado com a URL da imagem
        })
        .catch((error) => {
          setLogotipoUrl(null);
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
    }
    if (token) {
      fetch(`https://api.aliviaapp.com.br/api/v1/adm/arquivo/icone/${token}`)
        .then((response) => {
          if (response.ok) {
            return response.blob(); // Trata a resposta como um blob (imagem)
          }
          throw new Error("Network response was not ok.");
        })
        .then((blob) => {
          // Cria uma URL temporária para o blob
          const imgUrl = URL.createObjectURL(blob);
          setIconeUrl(imgUrl); // Atualiza o estado com a URL da imagem
        })
        .catch((error) => {
          setIconeUrl(null);
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
    }
  }, [token, loadingConfig]);

  const ButtonSave = styled.button`
    background-color: transparent;
    color: ${color};
    width: 80%;
    padding: 10px 0px;
    border-radius: 7px 0px 7px 0px;
    border: 1px solid ${color};
    font-weight: bold;
    margin-top: 15px;
    cursor: pointer;
    &:hover {
      background-color: ${color};
      color: #fff;
      transition: 0.3s ease-in-out;
    }
  `;

  // Função para selecionar a cor
  async function SelecionarCor() {
    setLoading(true);

    try {
      await http.post("/api/v1/adm/config/edit", {
        paleta: color.toUpperCase(),
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  // Chamada GetConfig no carregamento inicial
  useEffect(() => {
    GetConfig();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loading loading={loading} />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <span style={{ color: color }}>
                <b>Cor</b>: {color}
              </span>

              <SketchPicker
                color={color}
                onChange={(selectedColor) => setColor(selectedColor.hex)}
              />
              <ButtonSave onClick={SelecionarCor}>Salvar essa cor</ButtonSave>
            </div>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <img style={{ background: color, width: 250 }} src={mockupt} />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <div
              style={{ display: "flex", flexDirection: "column", fontSize: 18, alignContent: 'center', alignItems: "center" }}
            >
              Logotipo horizontal 790x307px
              <br />
              {logotipoUrl !== null && (
                <img
                  src={logotipoUrl}
                  style={{ width: "100%" }}
                  alt="Logotipo"
                />
              )}
              <ButtonSave onClick={() => setModalLogo(true)}>
                {" "}
                {logotipoUrl === null ? "Inserir Logo" : "Alterar logo"}
              </ButtonSave>
            </div>
            <Divider />
            <div
              style={{ display: "flex", flexDirection: "column", fontSize: 18, alignContent: 'center', alignItems: "center" }}
            >
              Icone 350x350px
              <br />
              {iconeUrl !== null && (
                <img src={iconeUrl} style={{ width: 100 }} alt="Logotipo" />
              )}
              <ButtonSave onClick={() => setModalIcone(true)}>
                {iconeUrl === null ? "Inserir icone" : "Alterar icone"}
              </ButtonSave>
            </div>
          </Grid>
        </Grid>
      </MDBox>
      <Logotipos
        open={modalLogo}
        close={() => setModalLogo(false)}
        loading={loadingConfig}
      />
      <IconeComponent
        open={modalIcone}
        close={() => setModalIcone(false)}
        loading={loadingConfig}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default ConfiguracoesLayout;
