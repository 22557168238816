import customQueryStringify from "../../helpers/queryString";
import http from "../../services/api";
import store from "../index";
import * as types from "./../types/empresas";

export function loadEmpresas(query) {
  const qrt = customQueryStringify(query);

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.EMPRESAS_LOADING, payload: true });
    http.get(`/api/v1/adm/empresas/listar?${qrt}`).then(
      (response) => {
        store.dispatch([
          { type: types.EMPRESAS_DATA, payload: response.data.empresas },
          { type: types.EMPRESAS_DATA_PAGE, payload: response.data.page },
          { type: types.EMPRESAS_DATA_PER_PAGE, payload: response.data.per_page },
          { type: types.EMPRESAS_DATA_TOTAL, payload: response.data.total },
          { type: types.EMPRESAS_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.EMPRESAS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ActionCadatrarEmpresaWl(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.EMPRESAS_LOADING, payload: true });
    http.post(`/api/v1/adm/empresas/cadastro-empresa`, data).then(
      (response) => {
        store.dispatch([{ type: types.EMPRESAS_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.EMPRESAS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ActionAtualizarEmpresa(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.EMPRESAS_LOADING, payload: true });
    http.put(`/api/v1/adm/empresas/atualizar`, data).then(
      (response) => {
        store.dispatch([{ type: types.EMPRESAS_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.EMPRESAS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function loadClientesEmpresa(id, query) {
  const _qry = customQueryStringify(query)
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.EMPRESAS_LOADING, payload: true });
    http.get(`/api/v1/adm/empresas/listar/${id}?${_qry}`).then(
      (response) => {
        store.dispatch([
          { type: types.EMPRESAS_CLIENTES_DATA, payload: response.data.clientes },
          { type: types.EMPRESAS_CLIENTES_DATA_PAGE, payload: response.data.page },
          { type: types.EMPRESAS_CLIENTES_DATA_PER_PAGE, payload: response.data.per_page },
          { type: types.EMPRESAS_CLIENTES_DATA_TOTAL, payload: response.data.total },
          { type: types.EMPRESAS_SELECIONADA_DATA, payload: response.data.empresa },
          { type: types.EMPRESAS_LOADING, payload: false },
        ]);

        console.log(response.data.total )
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.EMPRESAS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}


export function ActionExcluirEmpresaEClientes(id) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.EMPRESAS_LOADING, payload: true });
    http.delete(`/api/v1/adm/empresas/excluir/${id}`).then(
      (response) => {
        store.dispatch([         
          { type: types.EMPRESAS_LOADING, payload: false },
        ]);

        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.EMPRESAS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}


export function ActionCadatrarClienteExcel(id, data) {
  const formData = new FormData();
  formData.append('excelFile', data); 

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.EMPRESAS_LOADING, payload: true });
    http.post(`/api/v1/adm/cliente/excel/${id}`,  formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }}).then(
      (response) => {
        store.dispatch([
          { type: types.EMPRESAS_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.EMPRESAS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}


export function ActionDefinirEmpresaCadastroApp(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.EMPRESAS_LOADING, payload: true });
    http.post(`/api/v1/adm/config/cadastro-empresa`, data).then(
      (response) => {
        store.dispatch([{ type: types.EMPRESAS_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.EMPRESAS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}
