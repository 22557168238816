import { Dialog, DialogContent, styled } from '@mui/material';
import { useEffect } from 'react';

// Estilo personalizado para o modal
const CustomDialog = styled(Dialog)(({ theme, width, height }) => ({
  '& .MuiDialog-paper': {
    width: width || '80%',
    height: height || 'auto', // Use a altura personalizada ou 'auto' como padrão
    maxWidth: 'none',
  },
}));

const ModalComponent = ({ open, onClose, width, height, children }) => {
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (open) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [open, onClose]);

  return (
    <CustomDialog open={open} onClose={onClose} width={width} height={height}>
      <DialogContent>
        {children}
      </DialogContent>
    </CustomDialog>
  );
};

export default ModalComponent;