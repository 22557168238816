import { forwardRef } from "react";
import PropTypes from "prop-types";
import MDInputRoot from "components/MDInput/MDInputRoot";

const MDInput = forwardRef(({ error, success, disabled, placeholder, ...rest }, ref) => (
  <MDInputRoot
    {...rest}
    ref={ref}
    ownerState={{ error, success, disabled }}
    placeholder={placeholder}
  />
));

MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
  placeholder: "", // Default placeholder is an empty string
};

MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string, // Placeholder should be a string
};

export default MDInput;
