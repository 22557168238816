import React, { useState, useEffect } from 'react';

const AlertComponent = ({ type, message, timeout }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, timeout);

    return () => clearTimeout(timer);
  }, [timeout]);

  if (!visible) return null;

  const alertStyle = {
    position: 'fixed',
    bottom: '25px',
    right: '25px',
    padding: '10px',
    borderRadius: '5px',
    color: '#fff',
    zIndex: 9999,
    fontSize: 14,
  };

  const successStyle = {
    ...alertStyle,
    backgroundColor: 'green',
  };

  const errorStyle = {
    ...alertStyle,
    backgroundColor: 'red',
  };

  return (
    <div style={type === 'success' ? successStyle : errorStyle}>
      {message}
    </div>
  );
};

export default AlertComponent;