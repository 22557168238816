import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";
import Loading from "components/Loading";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import http from "services/api";
import Logotipos from "./logotipos";
import styled from "@emotion/styled";
import { CarregarBanners } from "store/actions/campanhas";
import { baseURL } from "services/baseURL";
import UrlModal from "./urlModal";
import { DeleteOutline, Link } from "@mui/icons-material";
import { DeletarCampanhaTotal } from "store/actions/campanhas";

function CampanhasLayout() {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("");
  const { loadingConfig, banners } = useSelector((state) => state.campanhas);
  const [modalLogoHorizontal, setModalLogoHorizontal] = useState(false);
  const [modalLogoVertical, setModalLogoVertical] = useState(false);
  const [modalLinkCampanha, setModalLinkCampanha] = useState(false);
  const [urlLinkCampanha, setUrlLinkCampanha] = useState({});
  const [dataBanner, setDataBanner] = useState("");
  const [openDialog, setOpenDialog] = useState("");
  const [dataDialog, setDataDialog] = useState("");
  const [idBanner, setIdBanner] = useState("");

  // Função para obter configurações
  async function GetConfig() {
    setLoading(true);
    try {
      await http.get("/api/v1/adm/config").then((res) => {
        setColor(res.data.paleta);
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const [logotipoUrl, setLogotipoUrl] = useState(null);

  const ButtonSave = styled.button`
    background-color: transparent;
    color: ${color};
    width: 80%;
    padding: 10px 0px;
    border-radius: 7px 0px 7px 0px;
    border: 1px solid ${color};
    font-weight: bold;
    margin-top: 15px;
    cursor: pointer;
    &:hover {
      background-color: ${color};
      color: #fff;
      transition: 0.3s ease-in-out;
    }
  `;

  // Chamada GetConfig no carregamento inicial
  useEffect(() => {
    GetConfig();
    CarregarBanners();
  }, []);

  const urlConfig = baseURL();

  const styleBanner = {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: 8,
    border: `1px solid ${color}`,
    borderRadius: "0px 15px 0px 15px",
    fontSize: 14,
  };

  async function DeleteCampanh() {
    setLoading(true);
    try {
      await DeletarCampanhaTotal(dataDialog.id);
      setLoading(false);

      location.reload();
    } catch (e) {
      setLoading(false);
    }
  }

  function OpenDialogExclusao(data) {
    setOpenDialog(true);
    setDataDialog(data);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Loading loading={loading} />
      Você pode criar até <b>5</b> campanhas <br />
      <Divider />
      <div style={{ flexDirection: "column", display: "flex", fontSize: 12 }}>
        <span>Regras para criar uma campanha:</span>
        <span style={{ marginLeft: 14 }}>
          ◎ O banner horizontal deverá ter <b>1050x450px</b>
        </span>
        <span style={{ marginLeft: 14 }}>
          ◎ O banner vertical deverá ter <b>1.080x1.920px</b>
        </span>
        <span style={{ marginLeft: 14 }}>
          ◎ A URL deverá ser um <b>link válido</b>
        </span>
      </div>
      <MDBox py={3}>
        {banners.length === 0 ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={4}>
                <div style={{ fontSize: 14 }}>
                  <div style={{ ...styleBanner, height: "100%", textAlign: 'center' }}>
                  <span>Vamos criar a <b>primeira campanha</b>, começe inserindo o banner horizontal </span><br />
                    Horizontal <b>1050x450px</b>
                    <br />
                    {logotipoUrl !== null && (
                      <img
                        src={logotipoUrl}
                        style={{ width: "50%" }}
                        alt="Logotipo"
                      />
                    )}
                    <ButtonSave onClick={() => setModalLogoHorizontal(true)}>
                      Inserir Banner
                    </ButtonSave>
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            {" "}
            <Grid container spacing={3}>
              {banners.map((item) => {
                return (
                  <Grid item xs={12} md={2.4} lg={2.4} key={item.id}>
                    <div style={{ fontSize: 14 }}>
                      <div style={{ ...styleBanner }}>                  
                        Horizontal 1050x450px
                        <img
                          crossorigin="anonymous"
                          style={{ width: "100%" }}
                          src={`${urlConfig}/api/v1/adm/banner/img/${item.white_label_id}/${item.horizontal}`}
                          alt="logo"
                        />
                        <ButtonSave
                          onClick={() => [
                            setModalLogoHorizontal(true),
                            setDataBanner(item.horizontal),
                            setIdBanner(item.id),
                          ]}
                        >
                          Alterar Banner
                        </ButtonSave>
                        Vertical 1.080x1.920px <br />
                        <img
                          crossorigin="anonymous"
                          style={{ width: 100 }}
                          src={`${urlConfig}/api/v1/adm/banner/img/${item.white_label_id}/${item.vertical}`}
                          alt="logo"
                        />
                        <ButtonSave
                          onClick={() => [
                            setModalLogoVertical(true),
                            setDataBanner(item.vertical),
                            setIdBanner(item.id),
                            console.log(item.id),
                          ]}
                        >
                          Alterar Banner
                        </ButtonSave>
                        <Divider />
                        <ButtonSave
                          onClick={() => [
                            setModalLinkCampanha(true),
                            setUrlLinkCampanha(item),
                          ]}
                        >
                          {item.url ? "Alterar link" : "Criar Link"}
                        </ButtonSave>
                        <br />
                        {item.url && (
                          <a href={item.url} target="_blank">
                            <Link /> Acessar link
                          </a>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 15,
                      }}
                    >
                      <button
                        style={{
                          border: "none",
                          cursor: "pointer",
                          color: "red",
                          fontSize: 12,
                        }}
                        onClick={() => OpenDialogExclusao(item)}
                      >
                        <DeleteOutline /> Excluir Campanha
                      </button>
                    </div>
                  </Grid>
                );
              })}
              {banners.length < 5 && (
                <Grid item xs={12} md={2.4} lg={2.4}>
                  <div
                    style={{
                      ...styleBanner,
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    Horizontal 1050x450px
                    <ButtonSave
                      onClick={() => [
                        setModalLogoHorizontal(true),
                        setDataBanner(""),
                        setIdBanner(""),
                      ]}
                    >
                      Inserir Horizontal
                    </ButtonSave>
                    <br />
                  </div>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </MDBox>
      <Logotipos
        open={modalLogoHorizontal}
        close={() => setModalLogoHorizontal(false)}
        loading={loadingConfig}
        column="horizontal"
        largura="1050"
        altura="450"
        idBanner={dataBanner}
        id={idBanner}
      />
      <Logotipos
        open={modalLogoVertical}
        close={() => setModalLogoVertical(false)}
        loading={loadingConfig}
        column="vertical"
        largura="1080"
        altura="1920"
        idBanner={dataBanner}
        id={idBanner}
      />

      <UrlModal
        open={modalLinkCampanha}
        data={urlLinkCampanha}
        close={() => setModalLinkCampanha(false)}
      />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Deseja realmente excluir a campanha?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Esta ação resultará na exclusão da campanha da listagem do
            aplicativo. Uma vez excluída, a campanha não poderá ser recuperada,
            e para exibi-la novamente será necessário realizar um novo cadastro.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
        {!loading && 
         <Button onClick={() => DeleteCampanh()} autoFocus>
            Sim, excluir!
          </Button>
          }
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default CampanhasLayout;
