import { Divider, Grid } from "@mui/material";
import axios from "axios";
import AlertComponent from "components/AlertComponent";
import Loading from "components/Loading";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import formatarCEP from "helpers/formatCEP";
import formatName from "helpers/formatName";
import MaskCPFCNPJ from "helpers/maskCpfCnpj";
import { removeDots } from "helpers/removeDots";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActionEditarParceiro } from "store/actions/parceiros";

const { default: ModalComponent } = require("components/Modal");

function ParceiroEdicaoModal({ open, close, updateList, data }) {
  const navigate = useNavigate();
  const [clienteData, setClienteData] = useState({
    nome: "",
    nome_exibicao: "",
    cpf_cnpj: "",
    email: "",
    cep: "",
    endereco: "",
    numero: "",
    bairro: "",
    cidade: "",
    uf: "",
    complemento: "",
    logo: "",
  });

  useEffect(() => {
    if (data) {
      setClienteData({
        ...data,
        nome_exibicao: data.nome_fantasia,
      });
    }
  }, [data]);

  console.log(data);

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [dataMessage, setDataMessage] = useState("");
  const [cadastroLoading, setCadastroLoading] = useState(false);

  const [novoCadastro, setNovoCadastro] = useState(false);

  function CloseModalFnc() {
    close();
    updateList();
    setClienteData({
      nome: "",
      nome_exibicao: "",
      cpf_cnpj: "",
      email: "",
      cep: "",
      endereco: "",
      numero: "",
      bairro: "",
      cidade: "",
      uf: "",
      complemento: "",
    });
  }

  async function EditarParceiro() {
    const dataFormatada = {  
      cpf_cnpj: removeDots(clienteData.cpf_cnpj),
      cep: removeDots(clienteData.cep),
      nome: clienteData.nome,
      nome_fantasia: clienteData.nome_exibicao,
      email: clienteData.email,
      cep: clienteData.cep,
      endereco: clienteData.endereco,
      numero: clienteData.numero,
      bairro: clienteData.bairro,
      cidade: clienteData.cidade,
      uf: clienteData.uf,
      complemento: clienteData.complemento,
      id: clienteData.id
    };
    try {
      await ActionEditarParceiro(dataFormatada).then((res) => {
        setShowSuccessAlert(true);
        setTypeMessage("success");
        setDataMessage(`Parceiro editado com sucesso: ${clienteData.nome}`);
        setTimeout(() => {
          setShowSuccessAlert(false);
          setTypeMessage("");
          setDataMessage("");
        }, 3000);
        CloseModalFnc();
      });
    } catch (e) {
      setShowSuccessAlert(true);
      setTypeMessage("error");
      setDataMessage(
        e.response ? e.response.data.titulo : "Erro ao editar cadastro"
      );
      setTimeout(() => {
        setShowSuccessAlert(false);
        setTypeMessage("");
        setDataMessage("");
      }, 3000);
    }
  }

  const styleClos = {
    backgroundColor: "transparent",
    color: "red",
    border: "none",
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: 12,
  };

  async function GetDataCep(data) {
    setCadastroLoading(true);
    const result = await axios.get(`https://viacep.com.br/ws/${data}/json`);
    setCadastroLoading(false);

    return result.data;
  }

  useEffect(() => {
    if (clienteData.cep && clienteData.cep.length === 8) {
      GetDataCep(clienteData.cep).then((res) => {
        console.log(res);
        setClienteData({
          ...clienteData,
          endereco: res.logradouro,
          bairro: res.bairro,
          cidade: res.localidade,
          uf: res.uf,
        });
      });
    }
  }, [clienteData.cep]);

  return (
    <>
      <ModalComponent open={open} width="60%">
        <Loading loading={cadastroLoading} />
        {showSuccessAlert && (
          <AlertComponent
            type={typeMessage}
            message={dataMessage}
            timeout={3000}
          />
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <MDTypography variant="h6">Cadastro de cliente</MDTypography>
          <button style={{ ...styleClos }} onClick={CloseModalFnc}>
            FECHAR X
          </button>
        </div>
        <span style={{ fontSize: 12 }}>Todos os campos são obrigatórios</span>
        <Divider />

        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
            <MDInput
              type="text"
              label="Razão social *"
              fullWidth
              value={clienteData.nome ? formatName(clienteData.nome) : ""}
              onChange={(e) =>
                setClienteData({ ...clienteData, nome: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <MDInput
              type="text"
              label="Nome Exibição App *"
              fullWidth
              value={
                clienteData.nome_exibicao
                  ? formatName(clienteData.nome_exibicao)
                  : ""
              }
              onChange={(e) =>
                setClienteData({
                  ...clienteData,
                  nome_exibicao: e.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            <MDInput
              label="CPF/CNPJ *"
              placeholder="Documento *"
              fullWidth
              value={clienteData.cpf_cnpj}
              onChange={(e) =>
                setClienteData({ ...clienteData, cpf_cnpj: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            <MDInput
              type="text"
              label="CEP *"
              fullWidth
              value={
                clienteData.cep ? formatarCEP(clienteData.cep) : clienteData.cep
              }
              onChange={(e) =>
                setClienteData({ ...clienteData, cep: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <MDInput
              type="text"
              label="Endereço *"
              fullWidth
              value={
                clienteData.endereco ? formatName(clienteData.endereco) : ""
              }
              onChange={(e) =>
                setClienteData({ ...clienteData, endereco: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
            <MDInput
              label="Número *"
              placeholder="Número"
              fullWidth
              value={clienteData.numero}
              onChange={(e) =>
                setClienteData({ ...clienteData, numero: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            <MDInput
              type="text"
              label="Bairro *"
              fullWidth
              value={clienteData.bairro ? formatName(clienteData.bairro) : ""}
              onChange={(e) =>
                setClienteData({ ...clienteData, bairro: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            <MDInput
              type="text"
              label="Complemento"
              fullWidth
              value={
                clienteData.complemento
                  ? formatName(clienteData.complemento)
                  : ""
              }
              onChange={(e) =>
                setClienteData({ ...clienteData, complemento: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            <MDInput
              type="text"
              label="Cidade *"
              fullWidth
              value={clienteData.cidade ? formatName(clienteData.cidade) : ""}
              onChange={(e) =>
                setClienteData({ ...clienteData, cidade: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
            <MDInput
              label="UF *"
              placeholder="UF *"
              fullWidth
              value={clienteData.uf}
              onChange={(e) =>
                setClienteData({ ...clienteData, uf: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <MDInput
              type="email"
              label="Email *"
              placeholder="email@email.com"
              fullWidth
              value={clienteData.email}
              onChange={(e) =>
                setClienteData({ ...clienteData, email: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider />
            <MDButton
              style={{ marginTop: 10 }}
              variant="gradient"
              color="info"
              fullWidth
              onClick={EditarParceiro}
            >
              Editar Informações do Parceiro
            </MDButton>
          </Grid>
        </Grid>
      </ModalComponent>
    </>
  );
}

export default ParceiroEdicaoModal;
