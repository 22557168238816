import { Divider, Grid } from "@mui/material";
import AlertComponent from "components/AlertComponent";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import GenericRadioButton from "components/RadioButton";
import { formatCelular } from "helpers";
import { formatCPF } from "helpers";
import FormatBirth from "helpers/formatBirth";
import formatarDataNascimentoDB from "helpers/formatBirthDataBase";
import formatName from "helpers/formatName";
import { removeDots } from "helpers/removeDots";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ActionCadatrarClienteWl } from "store/actions/clientes";

const { default: ModalComponent } = require("components/Modal");

function ClienteCadastro({ open, close, updateList }) {
  const { clienteId } = useParams()


  const [clienteData, setClienteData] = useState({
    nome: "",
    cpf: "",
    email: "",
    celular: "",
    data_nascimento: "",
    sexo: "",
  });
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [dataMessage, setDataMessage] = useState("");

  const [novoCadastro, setNovoCadastro] = useState(false);

  useEffect(() => {
    const { nome, cpf, email, celular, data_nascimento, sexo } = clienteData;
    const isFilled = nome && cpf && email && celular && data_nascimento && sexo;
    setNovoCadastro(isFilled);
  }, [clienteData]);

  function CloseModalFnc(){
    close();
    updateList();
    setClienteData({
      nome: "",
      cpf: "",
      email: "",
      celular: "",
      data_nascimento: "",
      sexo: "",
    });
  }

  async function EfetuarCadastroFNC() {
    try {
      await ActionCadatrarClienteWl({
        ...clienteData,
        data_nascimento: formatarDataNascimentoDB(clienteData.data_nascimento),
        cpf: removeDots(clienteData.cpf),
        celular: removeDots(clienteData.celular),
        first_access: true,
        empresa_id: clienteId
      }).then((res) => {
        setShowSuccessAlert(true);
        setTypeMessage("success");
        setDataMessage(`Cliente cadastrado com sucesso: ${clienteData.nome}`);
        setTimeout(() => {
          setShowSuccessAlert(false);
          setTypeMessage("");
          setDataMessage("");
        }, 3000);
        CloseModalFnc()
   
      });
    } catch (e) {
      setShowSuccessAlert(true);
      setTypeMessage("error");
      setDataMessage(e.response ? e.response.data.message : "Erro ao efetuar cadastro");
      setTimeout(() => {
        setShowSuccessAlert(false);
        setTypeMessage("");
        setDataMessage("");
      }, 3000);
    }
  }

  const genderOptions = [
    { value: "M", label: "Masculino" },
    { value: "F", label: "Feminino" },
    { value: "I", label: "Não informar" },
    // Você pode adicionar mais opções conforme necessário
  ];

  const handleGenderChange = (event) => {
    setClienteData({ ...clienteData, sexo: event.target.value });
  };

  const styleClos = {
    backgroundColor: "transparent",
    color: 'red',
    border: 'none',
    cursor: 'pointer',
    fontWeight: "bold",
     fontSize: 12
  }

  return (
    <>
      <ModalComponent open={open} width="50%">
        {showSuccessAlert && (
          <AlertComponent type={typeMessage} message={dataMessage} timeout={3000} />
        )}

        <div style={{display: "flex", flexDirection: 'row', justifyContent: "space-between"}}>
          <MDTypography variant="h6">Cadastro de cliente</MDTypography>
          <button style={{...styleClos}} onClick={CloseModalFnc}>FECHAR X</button>
        </div>
        <span style={{ fontSize: 12 }}>Todos os campos são obrigatórios</span>
        <Divider />

        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <MDInput
              type="text"
              label="Nome Completo"
              fullWidth
              value={clienteData.nome ? formatName(clienteData.nome) : ""}
              onChange={(e) => setClienteData({ ...clienteData, nome: e.target.value })}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <MDInput
              label="CPF"
              placeholder="000.000.000-00"
              fullWidth
              value={formatCPF(clienteData.cpf)}
              onChange={(e) => setClienteData({ ...clienteData, cpf: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <MDInput
              type="email"
              label="Email"
              placeholder="email@email.com"
              fullWidth
              value={clienteData.email}
              onChange={(e) => setClienteData({ ...clienteData, email: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <MDInput
              type="text"
              label="Celular"
              placeholder="(00)00000-0000"
              fullWidth
              value={formatCelular(clienteData.celular)}
              onChange={(e) => setClienteData({ ...clienteData, celular: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <MDInput
              type="text"
              label="Data de Nascimento"
              placeholder="DD/MM/AAAA"
              fullWidth
              value={FormatBirth(clienteData.data_nascimento)}
              onChange={(e) => setClienteData({ ...clienteData, data_nascimento: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            <GenericRadioButton
              label="Sexo"
              name="gender"
              value={clienteData.sexo}
              options={genderOptions}
              onChange={handleGenderChange}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={EfetuarCadastroFNC}
              disabled={!novoCadastro}
            >
              Cadastrar
            </MDButton>
          </Grid>
        </Grid>
      </ModalComponent>
    </>
  );
}

export default ClienteCadastro;
