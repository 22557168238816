import http from "services/api";
import store from "../index";
import * as types from "./../types/configuracoes";


export function EnviarLogoParceiro(parametro, data) {
  const formData = new FormData();
  formData.append('foto_perfil', data); // 'logo' é o nome do campo que o backend espera receber

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.CONFIGURACOES_LOADING, payload: true });
    http.post(`/api/v1/adm/arquivo/${parametro}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(
      (response) => {
        store.dispatch([{ type: types.CONFIGURACOES_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.CONFIGURACOES_LOADING, payload: false });

        reject(e);
      }
    );
  });
}