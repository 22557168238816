import * as types from "../types/configuracoes";

const INITIAL_STATE = {
  loadingConfig: false,
  configuracoes: [],


};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CONFIGURACOES_LOADING:
      return { ...state, loadingConfig: action.payload };

    case types.CONFIGURACOES_DATA:
       return { ...state, configuracoes: action.payload };


    default:
      return state;
  }
};

