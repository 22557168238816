import http from "services/api";
import store from "../index";
import * as types from "./../types/campanhas";
import customQueryStringify from "helpers/queryString";


export function EnviarBanner(parametro, data, qry) {

const formatedQuery = { largura: qry.largura, altura: qry.altura, column: qry.column, id: qry.id}
const _qry = customQueryStringify(formatedQuery)



  const formData = new FormData();
  formData.append('foto_perfil', data); // 'logo' é o nome do campo que o backend espera receber

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.CAMPANHAS_LOADING, payload: true });
    http.post(`/api/v1/adm/banner/${parametro}/${qry.tipo}?${_qry}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(
      (response) => {
        store.dispatch([{ type: types.CAMPANHAS_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.CAMPANHAS_LOADING, payload: false });

        reject(e);
      }
    );
  });
}


export function CarregarBanners( ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.CAMPANHAS_LOADING, payload: true });
    http.get(`/api/v1/adm/banner/carregar`).then(
      (response) => {
        store.dispatch([
          { type: types.CAMPANHAS_LOADING, payload: false },
          { type: types.CAMPANHAS_DATA, payload: response.data }
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.CAMPANHAS_LOADING, payload: false });

        reject(e);
      }
    );
  });
}

export function SalvarUrlBannerAction(id, link ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.CAMPANHAS_LOADING, payload: true });
    http.post(`/api/v1/adm/banner/url/${id}`, link).then(
      (response) => {
        store.dispatch([
          { type: types.CAMPANHAS_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.CAMPANHAS_LOADING, payload: false });

        reject(e);
      }
    );
  });
}

export function DeletarCampanhaTotal(id ) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.CAMPANHAS_LOADING, payload: true });
    http.delete(`/api/v1/adm/banner/campanha/${id}`).then(
      (response) => {
        store.dispatch([
          { type: types.CAMPANHAS_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.CAMPANHAS_LOADING, payload: false });

        reject(e);
      }
    );
  });
}