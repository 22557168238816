function FormatBirth(data) {
  // Remove todos os caracteres não numéricos
  const numericData = data.replace(/\D/g, '');

  // Formata a data
  const day = numericData.substring(0, 2);
  const month = numericData.substring(2, 4);
  const year = numericData.substring(4, 8);

  let formattedDate = "";

  if (day) {
    formattedDate += day;
    if (day.length === 2 && month) {
      formattedDate += "/";
    }
  }
  if (month) {
    formattedDate += month;
    if (month.length === 2 && year) {
      formattedDate += "/";
    }
  }
  if (year) {
    formattedDate += year;
  }

  return formattedDate;
}

export default FormatBirth;
