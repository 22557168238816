import * as types from "../types/categorias";

const INITIAL_STATE = {
  loading: false,
  categorias: [],
  categoria: {},
  categorias_disponiveis: [],
  page: 1,
  per_page: 10,
  total: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CATEGORIAS_LOADING:
      return { ...state, loading: action.payload };

    case types.CATEGORIAS_DATA:
       return { ...state, categorias: action.payload };

    case types.CATEGORIA_DATA:
       return { ...state,   categoria: action.payload };

    case types.CATEGORIAS_DISPONIVEIS_PARCEIRO:
       return { ...state,   categorias_disponiveis: action.payload };

       case types.CATEGORIAS_DATA_PAGE:
        return { ...state, page: action.payload };
  
      case types.CATEGORIAS_DATA_PER_PAGE:
        return { ...state, per_page: action.payload };
  
      case types.CATEGORIAS_DATA_TOTAL:
        return { ...state, total: action.payload };


    default:
      return state;
  }
};

