import { applyMiddleware, combineReducers, createStore } from "redux";
import multi from "redux-multi";
import thunk from "redux-thunk";

import { categorias, clientes, configuracoes, parceiros, usuario, empresas, campanhas } from "./reducers";

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const reducers = combineReducers({
  usuario,
  clientes,
  parceiros,
  categorias,
  configuracoes,
  empresas,
  campanhas
});

export default applyMiddleware(thunk, multi)(createStore)(
  reducers,
  process.env.NODE_ENV === "development" ? devTools : undefined
);
