// @mui material components
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useEffect, useState } from "react";
import http from "services/api";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigate = useNavigate()

  const [dataBase, setDataBase] = useState({});

  async function GetMetricas() {
    await http.get("/api/v1/adm/metricas/listar").then((res) => {
      setDataBase(res.data);
    });
  }

  useEffect(() => {
    GetMetricas();
  }, []);


  const ButtonSeeMore = styled.button`
    color: gray;
    text-decoration: underline;
    cursor: pointer;
    background-color: transparent;
    border: none;
  `

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="persons"
                title="Clientes Ativos"
                count={dataBase.clientes_ativos}
                percentage={{
                  label: <ButtonSeeMore onClick={() => navigate("/empresas")} >ver mais</ButtonSeeMore>
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="person_add"
                title="Clientes Aguardando Aceite"
                count={dataBase.cliente_aguardando_aceite}
                percentage={{
                  label: <ButtonSeeMore onClick={() => navigate("/clientes-aceite")} >ver mais</ButtonSeeMore>
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="location_city"
                title="Empresas ativas"
                count={dataBase.empresas_ativas}
                percentage={{
                  label: <ButtonSeeMore onClick={() => navigate("/empresas")} >ver mais</ButtonSeeMore>
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_off"
                title="Clientes Cancelados"
                count={dataBase.clientes_cancelados}
                percentage={{
                  label: <ButtonSeeMore onClick={() => navigate("/clientes-cancelados")} >ver mais</ButtonSeeMore>
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="category"
                title="Categorias"
                count={dataBase.categorias_ativas}
                percentage={{
                  label: <ButtonSeeMore onClick={() => navigate("/categorias")} >ver mais</ButtonSeeMore>
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="category"
                title="Categorias Inativas"
                count={dataBase.categorias_inativas}
                percentage={{
                  label: <ButtonSeeMore onClick={() => navigate("/categorias")} >ver mais</ButtonSeeMore>
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="secondary"
                icon="location_city"
                title="Parceiros"
                count={dataBase.parceiros_ativos}
                percentage={{
                  label: <ButtonSeeMore onClick={() => navigate("/parceiros")} >ver mais</ButtonSeeMore>
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        {/* <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
