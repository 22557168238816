// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { formatCelular, formatCPF } from "helpers";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import { useEffect, useState } from "react";
import { loadClientes } from "store/actions/clientes";
import { useDispatch, useSelector } from "react-redux";
import formatName from "helpers/formatName";
import dayjs from "dayjs";
import { Paper, Tooltip } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import { ActionAceceptedOuRejected } from "store/actions/clientes";
import Loading from "components/Loading";
import ClienteCadastro from "./clienteCadastro";
import styled from "@emotion/styled";
import {
  EditOutlined,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
  LockReset,
  PersonOff,
} from "@mui/icons-material";
import { loadClientesEmpresa } from "store/actions/empresas";
import { useParams } from "react-router-dom";
import ModalComponent from "components/Modal";
import planilha from "../../../assets/images/planilha.jpg";
import { ActionCadatrarClienteExcel } from "store/actions/empresas";
import AlertComponent from "components/AlertComponent";
import * as types from "../../../store/types/empresas";
import excel from "../../../assets/images/excel.png";
import MDInput from "components/MDInput";
import { ActionSolicitarTokenResetCliente } from "store/actions/clientes";
import ModalTokenReset from "./modalTokenReset";

function EmpresasClientes() {
  const { clienteId } = useParams();
  const dispatch = useDispatch();
  const {
    empresaClientes,
    loading,
    empresaSelecionada,
    pageCliente,
    per_pageCliente,
    totalCliente,
  } = useSelector((state) => state.empresas);
  const [openModalCadastro, setOpenModalCadastro] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [dataMessage, setDataMessage] = useState("");
  const [modalPlanilha, setModalPlanilha] = useState(false);
  const [tokenReset, setTokenReset] = useState({});
  const [tokenResetModal, setTokenResetModal] = useState(false);
  const [fileExcel, setFileExcel] = useState(null);

  const [filtro, setFiltro] = useState("");
  const [filtroAtualizado, setFiltroAtualizado] = useState("");
  const [resetPage, setResetPage] = useState(false); // Estado para controlar o reset da página

  async function GetDataLoad() {
    const data = {
      page: resetPage ? 1 : pageCliente, // Se resetPage for true, volta para a página 1
      per_page: per_pageCliente,
      filtro: filtroAtualizado,
    };
    await loadClientesEmpresa(clienteId, data);
    setFiltroAtualizado(""); // Limpa o estado após a requisição
    setResetPage(false); // Reseta o estado de resetPage após a requisição
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (filtro === "") {
        GetDataLoad(); // Se filtro está vazio, chama a função GetDataLoad diretamente
      } else {
        setFiltroAtualizado(filtro);
        setResetPage(true); // Define resetPage como true ao aplicar o filtro
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [filtro, pageCliente]);

  useEffect(() => {
    if (filtroAtualizado !== "") {
      GetDataLoad();
    }
  }, [filtroAtualizado, pageCliente, resetPage]); // Adiciona resetPage como dependência

  const navigatePages = (validations) => {
    dispatch({ type: types.EMPRESAS_CLIENTES_DATA_PAGE, payload: validations });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileExcel(file);
    }
  };

  async function handleSave() {
    const file = new File([fileExcel], "excelFile", {
      type: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    });
    await ActionCadatrarClienteExcel(clienteId, file);
    setShowSuccessAlert(true);
    setTypeMessage("success");
    setDataMessage(`Planilha importada com sucesso`);
    setTimeout(() => {
      setShowSuccessAlert(false);
      setTypeMessage("");
      setDataMessage("");
    }, 3000);
    setModalPlanilha(false);
  }

  async function ActionAccepted(data, status) { 
    await ActionAceceptedOuRejected(data.clienteId, status).then(async (res) => {
      await GetDataLoad();

      if (status) {
        setShowSuccessAlert(true);
        setTypeMessage("success");
        setDataMessage(`Cliente removido com sucesso: ${data.nome}`);
        setTimeout(() => {
          setShowSuccessAlert(false);
          setTypeMessage("");
          setDataMessage("");
        }, 3000);
      } else {
        setShowSuccessAlert(true);
        setTypeMessage("error");
        setDataMessage(`Cliente recusado: ${data.nome}`);
        setTimeout(() => {
          setShowSuccessAlert(false);
          setTypeMessage("");
          setDataMessage("");
        }, 3000);
      }
    });
  }

  async function SolitarResetDeSenha(data) {
    console.log(data)
    try {
      const token = await ActionSolicitarTokenResetCliente(data.clienteId);
      setTokenReset(token);
      setTokenResetModal(true);
    } catch (e) {
      console.log(e);
    }
  }

  const Author = ({ nome, cpf }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {nome}
        </MDTypography>
        <MDTypography variant="caption">CPF: {cpf}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Contato = ({ email, celular }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {email}
      </MDTypography>
      <MDTypography variant="caption">{celular}</MDTypography>
    </MDBox>
  );

  const columns = [
    { Header: "Id", accessor: "id", align: "left" },
    { Header: "Data", accessor: "data", align: "left" },
    { Header: "Cliente", accessor: "nome", align: "left" },
    { Header: "Contato", accessor: "contato", align: "left" },
    {
      Header: "data de nascimento ",
      accessor: "data_nascimento",
      align: "center",
    },
    { Header: "Ação", accessor: "action", align: "center" },
  ];

  const styleBtn = {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  };

  const rows =
    empresaClientes &&
    empresaClientes.map((item) => {
      return {
        nome: <Author nome={formatName(item.nome)} cpf={item.cpf} />,
        contato: (
          <Contato email={item.email} celular={formatCelular(item.celular)} />
        ),
        data: dayjs(item.data_inicio).format("DD/MM/YYYY"),
        id: item.clienteId,
        data_nascimento: dayjs(item.data_nascimento).format("DD/MM/YYYY"),
        action: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Tooltip title="Solicitar Token de Reset de Senha">
                <button
                  style={{
                    ...styleBtn,
                    fontSize: 25,
                  }}
                  onClick={() => SolitarResetDeSenha(item)}
                >
                  <LockReset style={{ color: "#aaa62e" }} />
                </button>
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Remover Cliente">
                <button
                  style={{
                    ...styleBtn,
                    fontSize: 25,
                    marginLeft: 15,
                  }}
                  onClick={() => ActionAccepted(item, { aceite: false })}
                >
                  <PersonOff style={{ color: "#ff6e00" }} />
                </button>
              </Tooltip>
            </div>
          </div>
        ),
      };
    });

  const ButtonStyle = styled.button`
    background: #002244;
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
    border: 1px solid gray;
    cursor: pointer;
    font-size: 12px;
    &:hover {
      background: #2c3e50;
      transition: 0.3s ease-in-out;
    }
    &:active {
      background: #2c3e50;
      top: 2px;
    }
    &:before {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      line-height: 3;
      font-size: 140%;
      width: 60px;
    }
  `;

  const styledBtnArrows = {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    border: "none",
    cursor: "pointer",
    backgroundColor: "transparent",
    fontFamily: "Poppins",
  };

  const ButtonSave = styled.button`
    background-color: transparent;
    color: #21a366;
    /* width: 80%; */
    padding: 10px;
    display: flex;
    align-items: center;
    align-content: center;
    border-radius: 7px 0px 7px 0px;
    border: 1px solid #21a366;
    font-weight: bold;
    margin-top: 15px;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      background-color: #21a366;
      color: #fff;
      transition: 0.3s ease-in-out;
    }
  `;
  const ButtonCancel = styled.button`
    background-color: transparent;
    color: #961f1f;
    /* width: 80%; */
    padding: 10px;
    display: flex;
    align-items: center;
    align-content: center;
    border-radius: 7px 0px 7px 0px;
    border: 1px solid #961f1f;
    font-weight: bold;
    margin-top: 15px;
    font-size: 12px;
    margin-left: 25px;
    cursor: pointer;
    &:hover {
      background-color: #961f1f;
      color: #fff;
      transition: 0.3s ease-in-out;
    }
  `;

  return (
    <DashboardLayout>
      <Loading loading={loading} />
      <DashboardNavbar
        filtroNome="Buscar cliente por nome"
        valueFiltro={filtro}
        onChangeFiltro={(e) => setFiltro(e.target.value)}
      />

      <ButtonSave onClick={() => setModalPlanilha(true)}>
        <img src={excel} alt="Excel Icon" />
        &nbsp; IMPORTAR CLIENTES EXCEL
      </ButtonSave>

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <MDTypography variant="h6" color="white">
                    Clientes | {empresaSelecionada?.nome}
                  </MDTypography>
                  <ButtonStyle onClick={() => setOpenModalCadastro(true)}>
                    CADASTRAR CLIENTE
                  </ButtonStyle>
                </div>
              </MDBox>
              <Paper elevation={1} style={{ padding: 5 }}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={per_pageCliente}
                  showTotalEntries={totalCliente}
                  pageIndex={pageCliente}
                  noEndBorder
                />
                <div style={{ margin: 15 }}>
                  <div
                    style={{
                      marginRight: 30,
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {+pageCliente !== 1 && (
                        <button
                          style={styledBtnArrows}
                          onClick={() => navigatePages(+pageCliente - 1)}
                        >
                          <KeyboardArrowLeftOutlined /> Anterior{" "}
                        </button>
                      )}

                      {empresaClientes?.length !== 0 && (
                        <button
                          style={{ ...styledBtnArrows, marginLeft: 20 }}
                          onClick={() => navigatePages(+pageCliente + 1)}
                        >
                          Próximo <KeyboardArrowRightOutlined />
                        </button>
                      )}
                    </div>
                    <p
                      style={{
                        fontSize: 14,
                        backgroundColor: "transparent",
                        fontFamily: "Poppins",
                      }}
                    >
                      Pagina {pageCliente} | Total de clientes {totalCliente}
                    </p>
                  </div>
                </div>
              </Paper>
            </Card>
          </Grid>
        </Grid>
        <ClienteCadastro
          open={openModalCadastro}
          close={() => setOpenModalCadastro(false)}
          updateList={GetDataLoad}
        />

        {showSuccessAlert && (
          <AlertComponent
            type={typeMessage}
            message={dataMessage}
            timeout={3000}
          />
        )}

        <ModalComponent open={modalPlanilha}>
          <img src={planilha} alt="planilha" style={{ width: "100%" }} />
          <input
            id="fileSelect"
            onChange={handleFileChange}
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            {fileExcel && (
              <ButtonSave onClick={() => handleSave()}>
                Enviar planilha
              </ButtonSave>
            )}
            <ButtonCancel onClick={() => setModalPlanilha(false)}>
              Fechar{" "}
            </ButtonCancel>
          </div>
        </ModalComponent>

        <ModalTokenReset open={tokenResetModal} token={tokenReset.token} close={() => [setTokenReset({}), setTokenResetModal(false) ]} />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EmpresasClientes;
