// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { formatCelular, formatCPF } from "helpers";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import formatName from "helpers/formatName";
import { Paper, Tooltip } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import Loading from "components/Loading";
import styled from "@emotion/styled";
import CategoriasCadastro from "./categoriaCadastro";
import { loadCategorias } from "store/actions/categorias";
import MDBadge from "components/MDBadge";
import {
  CancelOutlined,
  DoDisturb,
  DoneOutline,
  EditOutlined,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
  RemoveDoneOutlined,
} from "@mui/icons-material";
import { ActionAtualizarCategoria } from "store/actions/categorias";
import * as types from "../../store/types/categorias";
import CategoriasEditar from "./categoriaCadastro/edit";
import MDInput from "components/MDInput";

function CategoriasApp() {
  const dispatch = useDispatch();
  const { categorias, loading,  page, per_page, total  } = useSelector((state) => state.categorias);


  const [filtro, setFiltro] = useState("");
  const [filtroAtualizado, setFiltroAtualizado] = useState("");
  const [resetPage, setResetPage] = useState(false);

  async function GetDataLoad() {
    const data = {
      page: resetPage ? 1 : page,
      per_page: per_page,
      filtro: filtroAtualizado, 
    };

    await loadCategorias(data);
    setFiltroAtualizado("");
    setResetPage(false);
  }


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (filtro === "") {
        GetDataLoad(); // Se filtro está vazio, chama a função GetDataLoad diretamente
      } else {
        setFiltroAtualizado(filtro);
        setResetPage(true);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [filtro, page]);

  useEffect(() => {
    if (filtroAtualizado !== "") {
      GetDataLoad();
    }
  }, [filtroAtualizado, page, resetPage]);



  const [openModalCadastro, setOpenModalCadastro] = useState(false);
  const [dataModalEditar, setDataModalEditar] = useState({});
  const [openModalEditar, setOpenModalEditar] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [typeMessage, setTypeMessage] = useState("");
  const [dataMessage, setDataMessage] = useState("");

  const navigatePages = (validations) => {
    dispatch({ type: types.CATEGORIAS_DATA_PAGE, payload: validations });
  };

  async function AtualizarCategoria(data) {
    try {
      await ActionAtualizarCategoria(data).then(async (res) => {
        await GetDataLoad();

        setShowSuccessAlert(true);
        setTypeMessage("success");
        setDataMessage(`Cliente aceito com sucesso: ${data.nome}`);
        setTimeout(() => {
          setShowSuccessAlert(false);
          setTypeMessage("");
          setDataMessage("");
        }, 3000);
      });
    } catch (e) {
      console.log(e);
    }
  }

  function ModalEditarCategoria(data) {
    setDataModalEditar(data);
    setOpenModalEditar(true);
  }

  const columns = [
    { Header: "Cliente", accessor: "nome", align: "left" },
    { Header: "Status", accessor: "status", align: "left" },
    { Header: "Ação", accessor: "action", align: "center" },
  ];

  const styleBtn = {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  };

  const rows = categorias.map((item) => {
    return {
      nome: <>{formatName(item.nome)}</>,
      status:
        item.status === "A" ? (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent="Ativo"
              color="success"
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ) : (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent="Inativo"
              color="dark"
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
      action: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Tooltip title="Editar informações">
              <button
                style={{
                  ...styleBtn,
                  fontSize: 25,
                }}
                onClick={() => ModalEditarCategoria(item)}
              >
                <EditOutlined style={{ color: "#1666b7" }} />
              </button>
            </Tooltip>
          </div>
          <div>
            {item.status === "A" ? (
              <Tooltip title="Desativar categoria">
                <button
                  style={{
                    ...styleBtn,
                    fontSize: 25,
                    marginLeft: 15,
                  }}
                  onClick={() =>
                    AtualizarCategoria({ status: "I", categoria_id: item.id })
                  }
                >
                  <DoneOutline style={{ color: "#18bc36" }} />
                </button>
              </Tooltip>
            ) : (
              <Tooltip title="Reativar categoria">
                <button
                  style={{
                    ...styleBtn,
                    fontSize: 25,
                    marginLeft: 15,
                  }}
                  onClick={() =>
                    AtualizarCategoria({ status: "A", categoria_id: item.id })
                  }
                >
                  <CancelOutlined style={{ color: "#515151" }} />
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      ),
    };
  });

  const ButtonStyle = styled.button`
    background: #002244;
    color: #fff;
    padding: 5px 15px;
    border-radius: 5px;
    border: 1px solid gray;
    cursor: pointer;
    font-size: 12px;
    &:hover {
      background: #2c3e50;
      transition: 0.3s ease-in-out;
    }
    &:active {
      background: #2c3e50;
      top: 2px;
    }
    &:before {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      line-height: 3;
      font-size: 140%;
      width: 60px;
    }
  `;

  const styledBtnArrows = {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    border: "none",
    cursor: "pointer",
    backgroundColor: "transparent",
    fontFamily: "Poppins",
  };
  return (
    <DashboardLayout>
      <Loading loading={loading} />
      <DashboardNavbar
        filtroNome="Filtro de Categoria por Nome"
        valueFiltro={filtro}
        onChangeFiltro={(e) => setFiltro(e.target.value)}
      />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <MDTypography variant="h6" color="white">
                    Categorias App
                  </MDTypography>
                  <ButtonStyle onClick={() => setOpenModalCadastro(true)}>
                    CADASTRAR CATEGORIA
                  </ButtonStyle>
                </div>
              </MDBox>
              <Paper elevation={1} style={{ padding: 5 }}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />

                <div style={{ margin: 15 }}>
                  <div
                    style={{
                      marginRight: 30,
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {+page !== 1 && (
                        <button
                          style={styledBtnArrows}
                          onClick={() => navigatePages(+page - 1)}
                        >
                          <KeyboardArrowLeftOutlined /> Anterior{" "}
                        </button>
                      )}

                      {categorias?.length !== 0 && (
                        <button
                          style={{ ...styledBtnArrows, marginLeft: 20 }}
                          onClick={() => navigatePages(+page + 1)}
                        >
                          Próximo <KeyboardArrowRightOutlined />
                        </button>
                      )}
                    </div>
                    <p
                      style={{
                        fontSize: 14,
                        backgroundColor: "transparent",
                        fontFamily: "Poppins",
                      }}
                    >
                      Pagina {page} | Total de categorias {total}
                    </p>
                  </div>
                </div>
              </Paper>
            </Card>
          </Grid>
        </Grid>
        <CategoriasCadastro
          open={openModalCadastro}
          close={() => setOpenModalCadastro(false)}
          updateList={GetDataLoad}
        />
        <CategoriasEditar
          open={openModalEditar}
          close={() => setOpenModalEditar(false)}
          updateList={GetDataLoad}
          data={dataModalEditar}
          setData={setDataModalEditar}
        />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CategoriasApp;
