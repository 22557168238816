import MDButton from "components/MDButton";

const { default: ModalComponent } = require("components/Modal");

function ModalTokenReset({ open, token, close }) {
  return (
    <ModalComponent open={open} width="auto" >

      <h3 style={{textAlign: 'center', fontWeight: 'normal'}}>Informe o Token : <b style={{textDecoration: 'underline'}}>{token}</b> para o Cliente</h3>
      <MDButton
        style={{ marginTop: 10 }}
        variant="gradient"
        color="info"
        fullWidth
        onClick={close}
      >
        Fechar Modal
      </MDButton>
    </ModalComponent>
  );
}

export default ModalTokenReset;
