
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";


import Icon from "@mui/material/Icon";
import ClientesApp from "layouts/clientes";

import SignIn from "layouts/authentication/sign-in";
import ClientesAceite from "layouts/clientesAceite";
import ClientesCancelados from "layouts/clientesCancelados";
import ParceirosApp from "layouts/parceiros";
import ParceiroDetalhes from "layouts/parceiros/parceiroDetalhes";
import CategoriasApp from "layouts/categorias";
import ConfiguracoesLayout from "layouts/configuracoes";
import EmpresasApp from "layouts/empresas";
import EmpresasClientes from "layouts/empresas/empresasClientes";
import CampanhasLayout from "layouts/campanhas";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  }, 
  {
    type: "collapse",
    name: "Empresas",
    key: "empresas",
    icon: <Icon fontSize="small">persons</Icon>,
    route: "/empresas",
    component: <EmpresasApp />,
  },
  { 
    route: "/empresas/:clienteId",
    component: <EmpresasClientes />,
  },
  // {
  //   type: "collapse",
  //   name: "Clientes",
  //   key: "clientes",
  //   icon: <Icon fontSize="small">persons</Icon>,
  //   route: "/clientes",
  //   component: <ClientesApp />,
  // },
  {
    type: "collapse",
    name: "Clientes Aceite",
    key: "clientes-aceite",
    icon: <Icon fontSize="small">person_add</Icon>,
    route: "/clientes-aceite",
    component: <ClientesAceite />,
  },
  {
    type: "collapse",
    name: "Clientes Cancelados",
    key: "clientes-cancelados",
    icon: <Icon fontSize="small">person_off</Icon>,
    route: "/clientes-cancelados",
    component: <ClientesCancelados />,
  },
  {
    type: "collapse",
    name: "Categorias",
    key: "categorias",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/categorias",
    component: <CategoriasApp />,
  },
  {
    type: "collapse",
    name: "Parceiros",
    key: "parceiros",
    icon: <Icon fontSize="small">location_city</Icon>,
    route: "/parceiros",
    component: <ParceirosApp />,
  },
  {
    type: "collapse",
    name: "Configurações",
    key: "configuracoes",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/configuracoes",
    component: <ConfiguracoesLayout />,
  },
  {
    type: "collapse",
    name: "Campanhas",
    key: "campanhas",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/campanhas",
    component: <CampanhasLayout />,
  },
  {
    type: "collapse",
    name: "Sair",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },

  {
    route: "/parceiros/:id",
    component: <ParceiroDetalhes />,
  },

];

export default routes;
