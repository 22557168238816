export const EMPRESAS_LOADING = 'EMPRESAS_LOADING';
export const EMPRESAS_DATA = 'EMPRESAS_DATA';
export const EMPRESA_DATA = 'EMPRESA_DATA';
export const EMPRESAS_CLIENTES_DATA = 'EMPRESAS_CLIENTES_DATA';
export const EMPRESAS_SELECIONADA_DATA = 'EMPRESAS_SELECIONADA_DATA';


export const EMPRESAS_DATA_PAGE = "EMPRESAS_DATA_PAGE";
export const EMPRESAS_DATA_PER_PAGE = "EMPRESAS_DATA_PER_PAGE";
export const EMPRESAS_DATA_TOTAL = "EMPRESAS_DATA_TOTAL";

export const EMPRESAS_CLIENTES_DATA_PAGE = "EMPRESAS_CLIENTES_DATA_PAGE"; 
export const EMPRESAS_CLIENTES_DATA_VALIDATION = "EMPRESAS_CLIENTES_DATA_VALIDATION"; 
export const EMPRESAS_CLIENTES_DATA_PER_PAGE = "EMPRESAS_CLIENTES_DATA_PER_PAGE";
export const EMPRESAS_CLIENTES_DATA_TOTAL = "EMPRESAS_CLIENTES_DATA_TOTAL";