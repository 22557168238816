import { Divider } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ButtonComponent, ButtonSave } from "./styles";
import { formatTelefone } from "helpers";
import { formatCelular } from "helpers";
import { removeDots } from "helpers/removeDots";
import { SalvarUrlBannerAction } from "store/actions/campanhas";

const { default: ModalComponent } = require("components/Modal");

function UrlModal({ open, data, close }) {
  const [urlCampanha, setUrlCampanha] = useState(data.url);
  const [urlWhatsapp, setUrlWhatsapp] = useState({ celular: "", mensagem: "" });
  const [value, setValue] = useState("whatsapp");

  const handleChange = (event) => {
    setValue(event.target.value);
    setUrlCampanha("");
  };

  function LinkWp() {
    const url = `https://api.whatsapp.com/send?phone=55${removeDots(urlWhatsapp.celular)}&text=${urlWhatsapp.mensagem}`;
    setUrlCampanha(url);
  }
  useEffect(() => {
    LinkWp();
  }, [urlWhatsapp.mensagem]);

  async function SaveUrl(){
    try{

      await SalvarUrlBannerAction(data.id, {link: urlCampanha })
      location.reload()
    }catch(e){
      console.log(e)
    }
  }

  return (
    <ModalComponent open={open} width="30%">
      <b>Criar url</b>
      <Divider />
      Selecione uma opção para criar um link do banner
      <br />
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="whatsapp"
            control={<Radio />}
            label="Link para Whatsapp"
          />
          <FormControlLabel
            value="outros"
            control={<Radio />}
            label="Outros links"
          />
        </RadioGroup>
      </FormControl>
      <br />
      {value === "whatsapp" && (
        <>
          <MDInput
          style={{marginTop: 14}}
            type="celular"
            label="Celular"
            fullWidth
            value={formatCelular(urlWhatsapp.celular)}
            onChange={(e) =>
              setUrlWhatsapp({ ...urlWhatsapp, celular: e.target.value })
            }
          />
         
          <MDInput
          style={{marginTop: 14}}
            type="mensagem"
            label="Mensagem"
            fullWidth
            value={urlWhatsapp.mensagem}
            onChange={(e) =>
              setUrlWhatsapp({ ...urlWhatsapp, mensagem: e.target.value })
            }
          />
          <br/>
        </>
      )}
      <MDInput
      style={{marginTop: 14}}
        type="url"
        label="Url"
        fullWidth
        value={urlCampanha}
        onChange={(e) => setUrlCampanha(e.target.value)}
      />
      <Divider />
      Link gerado, clique para testar:{" "}
      <a href={urlCampanha} target="_blank">
        {urlCampanha}
      </a>
      <ButtonSave onClick={() => SaveUrl()} >
        Salvar URL
      </ButtonSave>
      
      <ButtonComponent style={{marginTop: 14}} onClick={close} >
        Fechar
      </ButtonComponent>
    </ModalComponent>
  );
}

export default UrlModal;
