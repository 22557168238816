import styled from "@emotion/styled";
import { Divider } from "@mui/material";
import Loading from "components/Loading";
import ModalComponent from "components/Modal";
import { useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { EnviarLogoParceiro } from "store/actions/configuracoes";

function IconeComponent({ open, close, loading }) {
    const [image, setImage] = useState(null);
    const [editor, setEditor] = useState(null);
    const [scale, setScale] = useState(1);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
        }
    };

    const handleScaleChange = (e) => {
        const scale = parseFloat(e.target.value);
        setScale(scale);
    };

    async function handleSave() {
        if (editor) {
            const canvas = editor.getImageScaledToCanvas();
            canvas.toBlob(async (blob) => {
                const file = new File([blob], 'foto_perfil.jpg', { type: 'image/jpg' }); // Cria um arquivo do tipo File
                await EnviarLogoParceiro("icone", file);
                close()
            });
        }
    };


    const ButtonComponent = styled.button`
    background-color: transparent;
    color: #630b0b;
    width: 100%;
    padding: 15px 0px;
    border-radius: 7px 0px 7px 0px;
    border: 1px solid #630b0b;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      background-color: #630b0b;
      color: #fff;
      transition: 0.3s ease-in-out;
    }
  `;


  const ButtonSave = styled.button`
    background-color: transparent;
    color: #349b63;
    width: 100%;
    padding: 15px 0px;
    border-radius: 7px 0px 7px 0px;
    border: 1px solid #349b63;
    font-weight: bold;
    margin-top: 15px;
    cursor: pointer;
    &:hover {
      background-color: #349b63;
      color: #fff;
      transition: 0.3s ease-in-out;
    }
  `;

    return (
        <ModalComponent open={open} width="auto">
               <Loading loading={loading} />
            Definir icone 350x350px
            <Divider />
            <div style={{ display: 'flex', flexDirection: 'column', alignContent: "center", alignItems: "center" }}>
                <input type="file" accept="image/*" onChange={handleFileChange} />
                {image && (
                    <div>
                        <AvatarEditor
                            ref={(ref) => setEditor(ref)}
                            image={image}
                            width={350}
                            height={350}
                            border={50}
                            color={[0, 0, 0, 0.6]} // RGBA
                            scale={scale}
                            onPositionChange={() => { }} // Não estamos usando, mas necessário para a API
                        />
                        <div style={{ display: "flex", flexDirection: 'column', alignContent: "center", alignItems: "center" }}>
                            <input
                                type="range"
                                min="1"
                                max="4"
                                step="0.01"
                                value={scale}
                                onChange={handleScaleChange}
                            />
                            <div style={{ display: 'flex', flexDirection: "row", alignContent: "center", alignItems: "center", justifyContent: 'space-between' }}>

                            </div>

                        </div>
                <Divider />

                                <ButtonSave onClick={handleSave}>Salvar</ButtonSave>
                    </div>
                )}
                <Divider />
                <ButtonComponent onClick={close}>Fechar</ButtonComponent>
            </div>
        </ModalComponent>
    );
}

export default IconeComponent;
