import customQueryStringify from "../../helpers/queryString";
import http from "../../services/api";
import store from "../index";
import * as types from "./../types/usuario";

export const autenticarUsuario = async (data) => {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.USUARIO_LOADING, payload: true });
    http.post(`/api/v1/adm/usuarios/autenticar`, data).then(
      ({ data }) => {

        store.dispatch({ type: types.USUARIO_SET, payload: data });
        localStorage.setItem("token", data.token);
        localStorage.setItem("white_label", data.whiteLabelInfo.nome);
        localStorage.setItem("white_label_token", data.whiteLabelInfo.integracao_key);
        store.dispatch({ type: types.USUARIO_LOADING, payload: false });
        resolve();
      },
      (e) => {
        store.dispatch({ type: types.USUARIO_LOADING, payload: false });
        reject(e ? e.response.data : "Não foi possível efetuar o login");
      }
    );
  });
};





export function loadUsuario() {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.USUARIO_LOADING, payload: true });
    http.get(`/api/v1/usuarios/load`).then(
      (response) => {
        store.dispatch([
          { type: types.USUARIO_SET, payload: response.data },
          { type: types.USUARIO_SET_PERMISSOES, payload: response.data.permissao },
          { type: types.USUARIO_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.USUARIO_LOADING, payload: false });
        reject(e);
      }
    );
  });
}



export function AtendimentosFinalizadosUsuario(data) {

  const filter = customQueryStringify(data)

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.ATENDIMENTO_FINALIZADO_LOADING, payload: true });
    http.get(`/api/v1/atendimento/finalizados?${filter}`).then(
      (response) => {
        store.dispatch([
          { type: types.SET_ATENDIMENTOS_FINALIZADOS_USUARIO, payload: response.data },
          { type: types.ATENDIMENTO_FINALIZADO_PAGE, payload: response.data.pagination.currentPage },
          { type: types.ATENDIMENTO_FINALIZADO_PER_PAGE, payload: response.data.pagination.perPage },
          { type: types.ATENDIMENTO_FINALIZADO_TOTAL, payload: response.data.pagination.total },
          { type: types.ATENDIMENTO_FINALIZADO_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.ATENDIMENTO_FINALIZADO_LOADING, payload: false });
        reject(e);
      }
    );
  });
}