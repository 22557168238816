import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import GenericRadioButton from "components/RadioButton";
import { formatCelular } from "helpers";
import { useEffect, useState } from "react";
import { ActionEditarCategoria } from "store/actions/parceiros";

const { default: ModalComponent } = require("components/Modal");

function ModalEditarCadastro({ open, data, close, updateData }) {
  const [clienteData, setClienteData] = useState({});
  const whatsappOptions = [
    { value: true, label: "Sim" },
    { value: false, label: "Não" },
    // Você pode adicionar mais opções conforme necessário
  ];

  useEffect(() => {
    if (data) {
      setClienteData({
        ...data,
        whatsapp: data.celular_whatsapp === 1 ? true : false,
      });
    }
  }, [data]);

  const handleWhatsChange = (event) => {
    setClienteData({ ...clienteData, whatsapp: event.target.value });
  };

async function EditarCategoria(){
  try{
    await ActionEditarCategoria({
      celular: clienteData.celular ,
      whatsapp:  clienteData.whatsapp,
      descricao:  clienteData.descricao,
      id:  clienteData.id
    })
    await updateData()
    close()
  }catch(e){
    console.log(e)
  }
}

console.log(clienteData)

  return (
    <ModalComponent open={open} width="40%">
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        // height="100%"
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <MDInput
            type="text"
            label="Descrição"
            multiline
            fullWidth
            value={clienteData.descricao}
            onChange={(e) =>
              setClienteData({ ...clienteData, descricao: e.target.value })
            }
            inputProps={{ maxLength: 500 }}
          />
          <div style={{ fontSize: 12, textAlign: "right" }}>
            {clienteData?.descricao ? clienteData?.descricao?.length : 0}/500
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <MDInput
            type="text"
            label="Celular"
            placeholder="(00)00000-0000"
            fullWidth
            value={
              clienteData.celular
                ? formatCelular(clienteData.celular)
                : clienteData.celular
            }
            onChange={(e) =>
              setClienteData({ ...clienteData, celular: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <GenericRadioButton
            label="Este número de celular é Whatsapp?"
            name="whatsapp"
            value={clienteData.whatsapp}
            options={whatsappOptions}
            onChange={handleWhatsChange}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            onClick={() => EditarCategoria()}
            // disabled={!novoCadastro}
          >
            Editar
          </MDButton>
       
       
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <MDButton
            variant="gradient"
            color="secondary"
            fullWidth
            onClick={close}
            // disabled={!novoCadastro}
          >            
            Fechar
          </MDButton>       
        </Grid>
      </Grid>
    </ModalComponent>
  );
}

export default ModalEditarCadastro;
