import React from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';

function GenericRadioButton({ label, name, value, options, onChange }) {
  return (
    <FormControl component="fieldset" style={{display: 'flex', alignContent: "center", alignItems: "center", justifyContent: "center"}}>
      <FormLabel component="legend" style={{fontSize: 12}}>{label}</FormLabel>
      <RadioGroup
        aria-label={name}
        name={name}
        value={value}
        onChange={onChange}
        row
      >
        {options.map((option, index) => (
          <FormControlLabel key={index} value={option.value} control={<Radio />} label={option.label} />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default GenericRadioButton;
